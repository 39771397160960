<template>
    <div  class="page-s">
        <div  class="content">
            
            <!-- <div class="bg fadeIn animated" style="animation-delay:1s"></div> -->
            
            <div class="home sticky-header">
                <h1 class="fadeIn animated amalfi h-title2" style="animation-delay:1s;font-weight: 300;">Attire</h1>
                <h1 ref="ciao" class="fadeIn animated amalfi h-title" style="animation-delay:.5s; font-weight: 300;">Attire</h1>
            </div>
            <section v-if="false">
                <div class="page-width">
                    <div class="section1">
                        <div class="sec-img">
                            <img src="@/assets/flowers.png" alt="">
                        </div>
                        <div class="sec-body">
                            <h2 class="amalfi letterpress">Our Color Pallette</h2>
                        <div class="color-p">
                            <div class="color pink1"></div>
                            <div class="color pink2"></div>
                            <div class="color pink3"></div>
                            <div class="color pink4"></div>
                            <div class="color pink5"></div>
                        </div>
                        <span class="sackers">Welcome to our 'Enchanted Elegance' wedding color scheme, where romance meets sophistication. Here, we celebrate the harmonious blend of pinks, maroons, beiges, champagnes, and ivories, creating a palette that exudes warmth and love. The softness of pink symbolizes tender affection, while the depth of maroon adds a touch of passion and strength. Beige and champagne introduce a neutral elegance, grounding the scheme with their earthy, subtle sophistication. </span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <section>
                <div class="tabs">
                    <div @click="moveToIdx(0)" class="tab" :class="{'active':activeTab == 0}">Rehearsal Dinner</div>
                    <div @click="moveToIdx(1)" class="tab" :class="{'active':activeTab == 1}">Ceremony</div>
                    <div @click="moveToIdx(2)" class="tab" :class="{'active':activeTab == 2}">Post Brunch</div>
                    
                </div>
            </section>
            <section style="position:relative">
                <div class="prev-btn" @click="moveToIdx(activeTab > 0 ? activeTab - 1 : 2)"><i class="fal fa-chevron-left"></i> </div>
                <div class="next-btn" @click="moveToIdx(activeTab < 2 ? activeTab + 1 : 0)"><i class="fal fa-chevron-right"></i> </div>
            <Flicking ref="flicking" :options="{ circular: true, disableOnInit:true }">
                
                    <div key="0" class="tab-content rd">
                        <div class="overlay"></div>
                        <div class="page-width">
                        <div  class="section1 reverse">
                           
                            <div class="sec-body">
                                <h2 class="vogue letterpress" :enter="{ y: 0, opacity: 1, transition: { type: 'spring',  stiffness: 250, damping: 125,  mass: 0.5,}}" v-motion-slide-visible-bottom style="height:50px;letter-spacing: 5px;">Day <span style="color:#dcb4bd" class="amalfi">1</span> Jun 3rd</h2>
                                <h2 class="amalfi letterpress" :delay="300" v-motion-slide-visible-bottom>Rehearsal Dinner</h2>
                            <div class="sackers" :delay="300" v-motion-slide-visible-bottom>Cocktail Attire<br>
                               <div class="wear-sec"> <span class="g-tag"> <i class="fa fa-male"></i> men</span> <span>semi-formal wear, long sleeve button ups, slacks</span></div>
                               <div class="wear-sec"><span class="g-tag women"><i class="fa fa-female"></i> women</span> <span>midis, minis, long dresses</span></div>
                            </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    </div>

                    <div key="1" class="tab-content cm">
                        <div class="overlay"></div>
                        <div class="page-width">
                    <div  class="section1">
                       
                        <div class="sec-body">
                            <h2 class="vogue letterpress" v-motion-slide-visible-bottom style="letter-spacing: 5px; height:50px;">Day <span style="color:#dcb4bd" class="amalfi">2</span> Jun 4th</h2>
                            <h2 class="amalfi letterpress" v-motion-slide-visible-bottom>Ceremony</h2>
                            <div class="sackers" v-motion-slide-visible-bottom> Black Tie<br>
                                <div class="wear-sec"> <span class="g-tag"> <i class="fa fa-male"></i> men</span> <span>tuxedos or black suit</span></div>
                               <div class="wear-sec"><span class="g-tag women"><i class="fa fa-female"></i> women</span> <span>long gowns welcome but not required</span></div>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                    </div>
                    <div key="2" class="tab-content brunch">
                        <div class="overlay"></div>
                        <div class="page-width">
                    <div   class="section1 reverse">
                        
                        <div class="sec-body">
                            <h2 class="vogue letterpress" :delay="300" v-motion-slide-visible-bottom style="letter-spacing: 5px; height:50px">Day <span style="color:#dcb4bd" class="amalfi">3</span> Jun 5th</h2>
                            <h2 class="amalfi letterpress" :delay="300" v-motion-slide-visible-bottom>Post Brunch</h2>
                        <div class="sackers" :delay="300" v-motion-slide-visible-bottom>Pastel & floral encouraged<br>
                            <div class="wear-sec"> <span class="g-tag"> <i class="fa fa-male"></i> men</span> <span>Collared t-shirts, linens, shorts, (think: my dad owns a yacht)</span></div>
                               <div class="wear-sec"><span class="g-tag women"><i class="fa fa-female"></i> women</span> <span>summer dresses, floral prints, pastels, linens (cottage-core vibes)</span></div>
             
                           
                        </div>
                        </div>
                        
                    </div>
                    
                </div>
                    </div>
                    
                </Flicking>
            </section>
            <!-- <section>
                <div style="height:300px;"></div>
            </section> -->

    
        </div>
    </div>
    </template>
    <script>
     import Flicking from "@egjs/vue3-flicking";
     import ScrollOut from "scroll-out";
    export default {
        name:'Event',
        components:{
            Flicking
        },
        data(){
            return {
                isLoading:false,
                aimX:null,
                aimY:null,
                activeTab:0
            }
        },
        computed:{
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        },
        methods:{
            moveToIdx(idx){
                this.activeTab = idx;
                let flicking = this.$refs.flicking;
                flicking.moveTo(idx).catch(err=>{
                    if(err) return;
                    throw err;
                });
            }
        },
        mounted(){
            if(!this.isMobile){

           
            // ScrollOut({
            //     targets: ".sticky-header",
            //     cssProps: {
            //         viewportY: true,
            //         visibleY: true
            //     }
            // })
        }
            // function requestDeviceOrientation () {
            // if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            // DeviceOrientationEvent.requestPermission()
            // .then(permissionState => {
            // if (permissionState === 'granted') {
            // window.addEventListener('deviceorientation', () => {});
            // }
            // })
            // .catch(console.error);
            // } else {
            // // handle regular non iOS 13+ devices
            // console.log ("not iOS");
            // }
            // }
            // requestDeviceOrientation();
           
            let currentX = 0;
            let currentY = 0;
            this.aimX = 0;
            this.aimY = 0;
            let speed = 0.1;
            let windowHeight = 0;
            // if(this.$refs.ciao == null) return;
            // let {width, height} = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect();
            
            // window.addEventListener('resize', ()=>{
            //     height = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect().height;
            //     windowHeight = window.innerHeight;
            // });
            // const animate = () => {
            //     currentX += (this.aimX - currentX) * speed;
            //     currentY += (this.aimY - currentY) * speed;
            //     if(this.$refs.ciao == null)return;
            //     this.$refs.ciao.style.backgroundPosition = `${currentX}px ${currentY - windowHeight/2}px`
            //     requestAnimationFrame(animate)
            // }
            // animate();
            // window.addEventListener('mousemove', (e)=>{
                
            //     this.aimX = e.pageX/34;
            //     this.aimY = e.pageY/34
               
            // })
            
            
            // if(window.DeviceOrientationEvent) {
            //     window.addEventListener('deviceorientation', (event) => {
                    
            //     //let alpha = event.alpha;
            //      this.aimX = event.gamma /2;
            //      this.aimY = 150 + event.beta/2;
            // // let beta = event.beta;
            // // let gamma = event.gamma;
            //     // Do something
            //     });
            // }
        },
    
    }
    </script>
    <style lang="scss">
    .sticky-header[data-scroll] {
        transform:translatey(calc(var(--viewport-y) * 20vh));
        opacity: var(--visible-y);
    }
    </style>
    <style scoped lang="scss">
    .wear-sec{
        display:flex;
        gap:10px;
        align-items: flex-start;
        margin:50px 0px;
        @include media-query($small){
            margin:0;
            gap:0px;
            flex-direction: column;
            margin-top:20px;
        }
    }
    .prev-btn{
        position:absolute;
        left:50px;
        top:50%;
        transform:translateY(-50%);
        background:#9D6371;
        border-radius: 100px;
        height:50px;
        width:50px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
        z-index: 100;
        color:#fff;
        display:flex;
        transition: all .3s ease;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        @include media-query($small){
            top:0px;
            box-shadow: unset;
            left:5%;
            height:30px;
            width:30px;
        }
        &:hover{
            background:#733C50
        }
        display:flex;
        i{
            color:#fff;
            font-size:12pt;
            @include media-query($small){
                font-size:10pt
            }
           
        }
    }
    .next-btn{
        position:absolute;
        right:50px;
        top:50%;
        transform:translateY(-50%);
        background:#9D6371;
        border-radius: 100px;
        height:50px;
        width:50px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
        z-index: 100;
        color:#fff;
        @include media-query($small){
            top:0px;
            right:5%;
            height:30px;
            width:30px;
            box-shadow: unset;
        }
        display:flex;
        transition: all .3s ease;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        &:hover{
            background:#733C50
        }
        display:flex;
        i{
            color:#fff;
            font-size:12pt;
            @include media-query($small){
                font-size:10pt
            }
           
        }
    }
    .tabs{
        display:flex;
        align-items: center;
        background:#645657;
        padding:30px 10%;
        position:relative;
        justify-content: space-between;
        @include media-query($small){
            padding:30px 15px;
        }
        .tab{
            transition: all .3s ease;
            font-family: sackers;
            text-align: center;
            width:33.33%;
            text-transform: uppercase;
            letter-spacing: 4px;
            opacity: .5;
            font-size:15pt;
            color:#fff;
            position:relative;
            cursor: pointer;
            @include media-query($small){
                font-size:9pt;
                letter-spacing: 0px;
                padding:0px 15px;
            }
            &:hover{
                opacity: 1;
            }
            &.active{
                opacity: 1;
                color:#ffadc1;
                // &::before{
                //     content:'';
                //     width:100%;
                //     height:2px;
                //     transform:translateY(10px);
                //     position:absolute;
                //     left:0;
                //     bottom:0;
                //     background:#fff;
                // }
            }
        }
    }
    .g-tag{
        border-radius: 100px;
        background:#733C50;
        padding:0px 20px;
        color:#fff;
        white-space: nowrap;
        text-align: center;
        margin-right:10px;
        margin-bottom:10px;
        width:110px;
        font-size: 10pt;
        
        &.women{
            background:#9d6371;
        }
    }
    .overlay{
        position: absolute;
        top:0;
        width:100%;
        height:100%;
        background: linear-gradient(90deg, #352b2c, #dbd6cfa6);
        pointer-events: none;
        z-index: 1;
        @include media-query($small){
            background: linear-gradient(0deg, #352b2c, #645657b5);
        }
    }
    .tab-content{
        height:100vh;
        width:100vw;
        background-size:cover;
        background-position: center center;
        @include media-query($small){
            height:80vh;
        }
        &.rd{
            background-image:url('@/assets/rehearsal.jpg');
        }
        &.cm{
            background-image:url('@/assets/ceremonyarea.jpg');
        }
        &.brunch{
            background-image:url('@/assets/brunch.jpg');
        }
    }
    section{
        
    }
    .section1{
        display:flex;
        align-items: center;
        gap:5%;
        position: relative;
        z-index: 2;
        height:100vh;
        
        @include media-query($small){
            flex-wrap: wrap;
            align-items: flex-start;
            height:80vh;
        }
        &.reverse{
            flex-direction: row-reverse;
            @include media-query($small){
                flex-direction: row;
            }
        }
        .sec-img{
            @include media-query($small){
                flex-basis: 100%;
            }
            flex:1;
            // max-width:400px;

            
            position: relative;
            img{
                @include media-query($small){
                    max-height:300px;
                }
                box-shadow: 0px 6px 20px -5px rgba(0,0,0,0.3);
                height:100%;
                width:100%;
                object-fit: cover;
            }
        }
        .sec-body{
            flex:1;
             color:#fff;
             @include media-query($small){
                margin-bottom:55px;
            }
        h2{
            &:nth-of-type(2){
                font-size: 45pt;
                color:#DDB5BE;
                @include media-query($small){
                    font-size: 33pt;
                    text-align: center;
                    line-height: 1.8em;
                 }
            }
            font-size: 34pt;
            font-weight: lighter;
            color:#fff;
            @include media-query($small){
                font-size: 24pt;
                text-align: center;
            }
            
        }
        span{

        }
        .color-p{
            margin:20px 0px;
            display:flex;
            align-items: center;
            gap:20px;
            .color{
                border:solid 2px #fff;
                height:65px;
                width:65px;
                @include media-query($small){
                    height:12vw;
                    width:12vw;
                }
                border-radius: 100px;
                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.2);
                &.pink1{
                    background:#733C50;
                }
                &.pink2{
                    background:#9D6371;
                }   
                &.pink3{
                    background:#DDB5BE;
                }
                &.pink4{
                    background:#CCB2A3;
                }
                &.pink5{
                    background:#F2EEEB;
                }
            }
        }
    }
    }


    .bg{
        &::before{
            content:'';
            height: 100%;
            position:absolute;
            z-index: 3;
            top:0;
            left:0;
            width:100%;
            background:linear-gradient(180deg, #00000080, transparent);
        }
        display:none;
        background-image: url('../assets/venue/wide2.webp');
        background-size:cover;
        background-position: center;
        position: absolute;
        
        left:0;
        height:calc(100vh - 70px);
        width:100%;
        pointer-events: none;
        z-index: 1;
    }

    .h-title{
        position: relative;
        width: 100%;
        text-align: center;
         background: -webkit-linear-gradient(-90deg, #e7ddde, #645657);
        // background-image:url('@/assets/flowers.png');
        // background-size: contain;
        // background-position: center center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
       // color:#a69597;
        //text-shadow: 1px 1px 0px #fff, -1px -1px 1px #5e5253;
        z-index: 10;
        // color:#fff;
        font-size:12vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            font-size:12vw;
        }
        user-select: none;
    
    }
    .h-title2{
        position: absolute;
    
        background-size: cover;
        background-position: center;
        z-index: 7;
        text-shadow: 3px 4px 5px rgba(0,0,0,0.1);
        color:transparent;
        font-size:12vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            text-shadow: 3px 6px 5px rgba(0, 0, 0, 0.1);
            font-size:12vw;
        }
        user-select: none;
    
    }
    .content{
        position: relative;
        min-height:100%;
        width:100%;
        overflow: hidden;
    }
    .box{
        height:1000px;
        width:1000px;
    }
    .home{
        max-height:calc(100vh - 70px);
    padding-top:000px;
    width:100%;
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
        
    }
    .timeframe{
        display:flex;
        flex-direction: column;
        align-items: center;
        .date{
            font-size:52pt;
            @include media-query($small){
                font-size:38pt;
            }
            sup{
                font-size:.5em
            }
        }
        .time{
            display:flex;
            align-items: center;
            width: 581px;
            transform: translateX(147px);
            @include media-query($small){
                width: 100%;
                transform: translateX(42px);
            }
            sup{
                font-size:.5em
            }
            i{
                color:#000;
                font-size:10pt;
                display:flex;
                align-items: center;
                justify-content: center;
                height:25px;
                width:25px;
            }
            .timestamp{
                margin-right:10px;
                color:#645657;
                font-size:17pt;
                width:103px;
            }
            .event{
                margin-left:15px;
                color:#645657;
                font-size:22pt;
                @include media-query($small){
                    line-height:1.3em;
                    font-size:13pt;
                }
            }
        }
        img{
            margin:100px 0px;
            width:15%;
            @include media-query($small){
                width:35%;
            }
        }
        .line{
            width:1px;
            height:45px;
            background:#645657;
            transform: translateX(-21px);
        }
    }
    </style>