<template>
    <div  class="page-s">
        <div  class="content">
            
            <!-- <div class="bg fadeIn animated" style="animation-delay:1s"></div> -->
            
            <div class="home sticky-header">
                <h1 class="fadeIn animated amalfi h-title2" style="animation-delay:1s;font-weight: 300;">RSVP</h1>
                <h1 ref="ciao" class="fadeIn animated amalfi h-title" style="animation-delay:.5s; font-weight: 300;">RSVP</h1>
            </div>  
            <section>
                <div class="page-width" style="display:flex;justify-content: center;">
                    
                    <form @click.prevent class="form" :class="{'disabled':isLoading}">
                        <span>Please enter an email and info for each invited guest. Please note we have limited accomodations and additional uninvited guests will have to be approved.  Thank you!</span>
                        <div class="row">
                            <v-input small v-model:value="first_name" label="First Name"></v-input>
                            <v-input small v-model:value="last_name" label="Last Name"></v-input>
                        </div>
                        <v-input small v-model:value="email" label="Email"></v-input>
                        <div class="row" style="padding:15px 10%; justify-content: space-between;">
                            <v-checkbox label="Attending" v-model:value="attending"></v-checkbox>
                            <v-checkbox label="Not Attending" v-model:value="notAttending"></v-checkbox>
                        </div>
                        
                        <v-btn @click="submitForm" :loading="isLoading" color="#ff9900" block>Submit</v-btn>
                        <div style="height:55px;">
                        <transition enter-active-class="shake" leave-active-class="fadeOut">
                            <div class="errorM animated" v-if="errorMessage != ''">{{ errorMessage }}</div>
                        </transition>
                        <transition enter-active-class="fadeInUp" leave-active-class="fadeOut">
                            <div class="successM animated-fast" v-if="successMessage != ''">{{ successMessage }}</div>
                        </transition>
                        </div>
                    </form>
                </div>
            </section>


            <section>
                <div style="height:300px;"></div>
            </section>

    
        </div>
    </div>
    </template>
    <script>
     import Flicking from "@egjs/vue3-flicking";
     import ScrollOut from "scroll-out";
     import axios from 'axios';
    export default {
        name:'Event',
        components:{
            Flicking
        },
        data(){
            return {
                isLoading:false,
                aimX:null,
                aimY:null,
                activeTab:0,
                first_name:'',
                email:'',
                last_name:'',
                attending:true,
                hasError:false,
                errorMessage:'',
                successMessage:''
            }
        },
        computed:{
            notAttending:{
                get(){
                    return !this.attending;
                },
                set(v){
                    this.attending = false;
                }
            },
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        },
        methods:{
            cap(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            async submitForm(){
                this.isLoading = true;
                this.errorMessage = '';
                this.successMessage = '';
                let {first_name, last_name, email, attending } = this;
                let post = {
                    first_name, 
                    last_name,
                    email,
                    attending
                }
                
                if(this.first_name == '' || this.last_name == '' || this.email == ''){
                    this.errorMessage = 'Please fill out all fields.';
                    this.isLoading = false;
                    return;
                }else{
                    setTimeout(async ()=>{
                        try{
                            let {data} = await axios.post('/api/rsvp', post);
                            this.successMessage = this.attending ? `See you there, ${this.cap(this.first_name)}!` : `We still love you, ${this.cap(this.first_name)}!`;
                            this.email = '';
                            this.first_name = '';
                            this.last_name = '';
                            this.isLoading = false;
                        }catch(e){
                            this.isLoading = false;
                            this.errorMessage = e.response.data.message || 'There was an error. Please try again later.'
                        }
                        
                    },1000);
                }
            },
            moveToIdx(idx){
                this.activeTab = idx;
                let flicking = this.$refs.flicking;
                flicking.moveTo(idx).catch(err=>{
                    if(err) return;
                    throw err;
                });
            }
        },
        mounted(){
            if(!this.isMobile){

           
            // ScrollOut({
            //     targets: ".sticky-header",
            //     cssProps: {
            //         viewportY: true,
            //         visibleY: true
            //     }
            // })
        }
            // function requestDeviceOrientation () {
            // if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            // DeviceOrientationEvent.requestPermission()
            // .then(permissionState => {
            // if (permissionState === 'granted') {
            // window.addEventListener('deviceorientation', () => {});
            // }
            // })
            // .catch(console.error);
            // } else {
            // // handle regular non iOS 13+ devices
            // console.log ("not iOS");
            // }
            // }
            // requestDeviceOrientation();
           
            let currentX = 0;
            let currentY = 0;
            this.aimX = 0;
            this.aimY = 0;
            let speed = 0.1;
            let windowHeight = 0;
            // if(this.$refs.ciao == null) return;
            // let {width, height} = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect();
            
            // window.addEventListener('resize', ()=>{
            //     height = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect().height;
            //     windowHeight = window.innerHeight;
            // });
            // const animate = () => {
            //     currentX += (this.aimX - currentX) * speed;
            //     currentY += (this.aimY - currentY) * speed;
            //     if(this.$refs.ciao == null)return;
            //     this.$refs.ciao.style.backgroundPosition = `${currentX}px ${currentY - windowHeight/2}px`
            //     requestAnimationFrame(animate)
            // }
            // animate();
            // window.addEventListener('mousemove', (e)=>{
                
            //     this.aimX = e.pageX/34;
            //     this.aimY = e.pageY/34
               
            // })
            
            
            // if(window.DeviceOrientationEvent) {
            //     window.addEventListener('deviceorientation', (event) => {
                    
            //     //let alpha = event.alpha;
            //      this.aimX = event.gamma /2;
            //      this.aimY = 150 + event.beta/2;
            // // let beta = event.beta;
            // // let gamma = event.gamma;
            //     // Do something
            //     });
            // }
        },
    
    }
    </script>
    <style lang="scss">
    .errorM{
        display:flex;
        align-items: center;
        color:#693333;
        font-size:10pt;
        font-family: Sackers;
        height:45px;
        width:100%;
        text-align: center;
        justify-content: center;
    }
    .successM{
        display:flex;
        align-items: center;
        color:#ffffff;
        background:#9a8d8e;
        font-family: Sackers;
        padding:10px;
        box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.3);
        font-size: 14pt;
        height: 129px;
        @include media-query($small){
            font-size:12pt;
            height:55px;
        }
        width:100%;
        text-align: center;
        justify-content: center;
    }
    .form{
        max-width:500px;
        width:100%;
        display: flex;
        gap:15px;
        flex-direction: column;
        span{
            font-family: Sackers;
            padding-bottom: 10px;
            color:#352b2c;
            text-align: center;

        }
        &.disabled{
            pointer-events: none;
        }
        .row{
            display:flex;
            align-items: center;
            gap:15px;
        }
    }
    .sticky-header[data-scroll] {
        pointer-events: none;
        transform:translatey(calc(var(--viewport-y) * 20vh));
        opacity: calc(var(--visible-y));
    }
    </style>
    <style scoped lang="scss">
    .tabs{
        display:flex;
        align-items: center;
        background:#645657;
        padding:30px 10%;
        position:relative;
        justify-content: space-between;
        @include media-query($small){
            padding:30px 15px;
        }
        .tab{
            transition: all .3s ease;
            font-family: sackers;
            text-align: center;
            width:33.33%;
            text-transform: uppercase;
            letter-spacing: 4px;
            opacity: .5;
            font-size:15pt;
            color:#fff;
            position:relative;
            cursor: pointer;
            @include media-query($small){
                font-size:9pt;
                letter-spacing: 0px;
                padding:0px 15px;
            }
            &:hover{
                opacity: 1;
            }
            &.active{
                opacity: 1;
                color:#ffadc1;
                // &::before{
                //     content:'';
                //     width:100%;
                //     height:2px;
                //     transform:translateY(10px);
                //     position:absolute;
                //     left:0;
                //     bottom:0;
                //     background:#fff;
                // }
            }
        }
    }
    .overlay{
        position: fixed;
        top:0;
        width:100%;
        height:100%;
        background: linear-gradient(90deg, #352b2c, #dbd6cfa6);
        pointer-events: none;
        z-index: 1;
        @include media-query($small){
            background: linear-gradient(0deg, #352b2c, #645657b5);
        }
    }
    .tab-content{
        height:100vh;
        width:100%;
        background-size:cover;
        background-position: center center;
        @include media-query($small){
            height:70vh;
        }
        &.rd{
            background-image:url('@/assets/rehearsal.jpg');
        }
        &.cm{
            background-image:url('@/assets/ceremonyarea.jpg');
        }
        &.brunch{
            background-image:url('@/assets/brunch.jpg');
        }
    }
    section{
        
    }
    .section1{
        display:flex;
        align-items: center;
        gap:5%;
        position: relative;
        z-index: 2;
        height:100vh;
        @include media-query($small){
            flex-wrap: wrap;
            height:70vh;
        }
        &.reverse{
            flex-direction: row-reverse;
            @include media-query($small){
                flex-direction: row;
            }
        }
        .sec-img{
            @include media-query($small){
                flex-basis: 100%;
            }
            flex:1;
            // max-width:400px;

            
            position: relative;
            img{
                @include media-query($small){
                    max-height:300px;
                }
                box-shadow: 0px 6px 20px -5px rgba(0,0,0,0.3);
                height:100%;
                width:100%;
                object-fit: cover;
            }
        }
        .sec-body{
            flex:1;
             color:#fff;
             @include media-query($small){
                margin-bottom:55px;
            }
        h2{
            &:nth-of-type(2){
                font-size: 45pt;
                color:#DDB5BE;
                @include media-query($small){
                    font-size: 33pt;
                    text-align: center;
                    line-height: 1.8em;
                 }
            }
            font-size: 34pt;
            font-weight: lighter;
            color:#fff;
            @include media-query($small){
                font-size: 24pt;
                text-align: center;
            }
            
        }
        span{

        }
        .color-p{
            margin:20px 0px;
            display:flex;
            align-items: center;
            gap:20px;
            .color{
                border:solid 2px #fff;
                height:65px;
                width:65px;
                @include media-query($small){
                    height:12vw;
                    width:12vw;
                }
                border-radius: 100px;
                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.2);
                &.pink1{
                    background:#733C50;
                }
                &.pink2{
                    background:#9D6371;
                }   
                &.pink3{
                    background:#DDB5BE;
                }
                &.pink4{
                    background:#CCB2A3;
                }
                &.pink5{
                    background:#F2EEEB;
                }
            }
        }
    }
    }


    .bg{
        &::before{
            content:'';
            height: 100%;
            position:absolute;
            z-index: 3;
            top:0;
            left:0;
            width:100%;
            background:linear-gradient(180deg, #00000080, transparent);
        }
        display:none;
        background-image: url('../assets/venue/wide2.webp');
        background-size:cover;
        background-position: center;
        position: absolute;
        
        left:0;
        height:calc(100vh - 70px);
        width:100%;
        pointer-events: none;
        z-index: 1;
    }

    .h-title{
        position: relative;
        width: 100%;
        text-align: center;
         background: -webkit-linear-gradient(-90deg, #e7ddde, #645657);
        // background-image:url('@/assets/flowers.png');
        // background-size: contain;
        // background-position: center center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
       // color:#a69597;
        //text-shadow: 1px 1px 0px #fff, -1px -1px 1px #5e5253;
        z-index: 10;
        // color:#fff;
        font-size:12vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            font-size:12vw;
        }
        user-select: none;
    
    }
    .h-title2{
        position: absolute;
    
        background-size: cover;
        background-position: center;
        z-index: 7;
        text-shadow: 3px 4px 5px rgba(0,0,0,0.1);
        color:transparent;
        font-size:12vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            text-shadow: 3px 6px 5px rgba(0, 0, 0, 0.1);
            font-size:12vw;
        }
        user-select: none;
    
    }
    .content{
        position: relative;
        min-height:100%;
        width:100%;
        overflow: hidden;
    }
    .box{
        height:1000px;
        width:1000px;
    }
    .home{
        max-height:calc(100vh - 70px);
    padding-top:000px;
    width:100%;
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
        
    }
    .timeframe{
        display:flex;
        flex-direction: column;
        align-items: center;
        .date{
            font-size:52pt;
            @include media-query($small){
                font-size:38pt;
            }
            sup{
                font-size:.5em
            }
        }
        .time{
            display:flex;
            align-items: center;
            width: 581px;
            transform: translateX(147px);
            @include media-query($small){
                width: 100%;
                transform: translateX(42px);
            }
            sup{
                font-size:.5em
            }
            i{
                color:#000;
                font-size:10pt;
                display:flex;
                align-items: center;
                justify-content: center;
                height:25px;
                width:25px;
            }
            .timestamp{
                margin-right:10px;
                color:#645657;
                font-size:17pt;
                width:103px;
            }
            .event{
                margin-left:15px;
                color:#645657;
                font-size:22pt;
                @include media-query($small){
                    line-height:1.3em;
                    font-size:13pt;
                }
            }
        }
        img{
            margin:100px 0px;
            width:15%;
            @include media-query($small){
                width:35%;
            }
        }
        .line{
            width:1px;
            height:45px;
            background:#645657;
            transform: translateX(-21px);
        }
    }
    </style>