<template>
    <div ref="header" class="header">
        <!-- <i style="font-size:20pt;" class="fal fa-bars"></i> -->
        <div v-if="!isMobile" class="nav-items" :class="{'white-text':$route.path == '/location' || $route.path == '/travel'}">
            <div class="nav-item sackers "  :class="{'active': $route.path == link.path}" v-for="(link, i) in links" :key="i"><span @click="$router.push(link.path), navHit()">{{ link.name }}</span><img :style="`filter:${colors[i]}`" :src="images[i]"/></div>
        </div>
        <div v-else class="nav-items-m">
            <div class="menu-icon">
                
                    <div @click="menuOpen = !menuOpen" class="btn " :class="{'not-active':!menuOpen, 'active':menuOpen}">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                   
            </div>
            <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
            <div v-if="menuOpen" class="m-menu animated-med">
               <div class="nav-item sackers " @click="$router.push(link.path), menuOpen = false, navHit()" :class="{'active': $route.path == link.path}" v-for="(link, i) in links" :key="i"><span>{{ link.name }}</span></div> 
            </div>
        </transition>
            
        </div>
    </div>
</template>
<script>

export default {
    name:'Header',
    data(){
        return {
          scrollListener:null,
            images:[
                // require('../assets/watercolor/webp/1.webp'),
                //require('../assets/watercolor/webp/2.webp'),
               // require('../assets/watercolor/webp/3.webp'),
              //  require('../assets/watercolor/webp/4.webp'),
                require('../assets/watercolor/webp/5.webp'),
               // require('../assets/watercolor/webp/6.webp'),
                require('../assets/watercolor/webp/7.webp'),
                require('../assets/watercolor/webp/8.webp'),
                require('../assets/watercolor/webp/9.webp'),
                require('../assets/watercolor/webp/7.webp'),
                require('../assets/watercolor/webp/10.webp'),
                require('../assets/watercolor/webp/8.webp'),
                require('../assets/watercolor/webp/9.webp'),
            ],
            colors:[
            `invert(56%) sepia(45%) saturate(350%) hue-rotate(-40deg) brightness(85%) contrast(85%)`,
            `invert(25%) sepia(48%) saturate(383%) hue-rotate(-62deg) brightness(98%) contrast(89%)`,
            `invert(56%) sepia(45%) saturate(350%) hue-rotate(-40deg) brightness(85%) contrast(85%)`, 
            `invert(25%) sepia(48%) saturate(383%) hue-rotate(-62deg) brightness(98%) contrast(89%)`,
            `invert(56%) sepia(45%) saturate(350%) hue-rotate(-40deg) brightness(85%) contrast(85%)`,
            `invert(25%) sepia(48%) saturate(383%) hue-rotate(-62deg) brightness(98%) contrast(89%)`,
            `brightness(1) contrast(1)`
            ],
            menuOpen:false
        }
    },
    mounted(){
      this.scrollListener = window.addEventListener('scroll', (e)=>{
        
        let y = window.scrollY;
        
        if(y > window.innerHeight){
          
          this.$refs.header.classList.add('scrolled');
        }else{
          this.$refs.header.classList.remove('scrolled');
        }
      })
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollListener)
    },
    methods:{
      navHit(){
        setTimeout(()=>{
          window.scrollTo(0,0)
        },300)
       
      }
    },
    computed:{
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        links(){
            return [
                {
                    name:'Home',
                    path:'/',
                },
                {
                    name:'Location',
                    path:'/location',
                },
                {
                    name:'Event',
                    path:'/event',
                },
                {
                    name:'Travel',
                    path:'/travel',
                },
                {
                    name:'Accomodations',
                    path:'/accomodations',
                },
                {
                    name:'Attire',
                    path:'/dress-code',
                },
                {
                    name:'RSVP',
                    path:'/rsvp',
                },

                
            ]
        },
        
    },
}
</script>
<style lang="scss">

.menu-icon{
width:100%;
display:flex;
justify-content: flex-end;
align-items: center;
height:70px;
z-index: 1000;

.btn {
  top:15px;
  z-index: 100;
  width: 35px;
  cursor: pointer;
}

span {
  display: block;
  width: 100%;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
  border-radius: 3px;
  height: 3px;
  background: #645657;
  transition: all .3s;
  position: relative;
}

span + span {
  margin-top: 7px;
}

.active span:nth-child(1) {
  animation: ease .7s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 11px;
    transform: rotate(0);
  }
  100% {
    top: 11px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 11px;
    transform: rotate(45deg);
  }
  50% {
    top: 11px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 9px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 9px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
}
</style>
<style scoped lang="scss">
.nav-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:70px;
    flex:1;
    width:100%;
    gap:10%;
    @include media-query($small){
        gap:5%;
    }
    &.white-text{
        .nav-item{
            color:#fff !important;
            span{
                opacity: 1;
                &::after{
                    background:#fff;
                    
                }
            }
        }
    }
    .nav-item{
        
        position:relative;
        display:flex;
        align-items: center;
        text-align: center;
        transition:all .3s ease;
        font-size:10pt;
        &.active{
            span{
                opacity: 1;
                &::after{
                    transform: scaleX(1);
                }
            }
        }
        span{
          cursor: pointer;
            transition:all .3s ease;
            opacity: .5;
            user-select: none;
            &:hover{
             & + img{
                opacity: .7;
            }
            &::after{
                transform: scaleX(1);
            }
        }
            position: relative;
            z-index: 10;
        &::after{
            transition:all .3s ease;
            content:'';
            position:absolute;
            transform:scaleX(0);
            transform-origin: 0%;
            bottom:-1px;
            left:0;
            width:100%;
            height:2px;
            background:#000;
        }
        }
        img{
          pointer-events: none;
            z-index: 0;
            transition:all .3s ease;
            position:absolute;
            width:200px;
            top:0%;
            opacity: 0;
            left:50%;
            top:0%;
            transform:translate(-50%, -20%);
        }
        
        &.active{
            img{
                opacity: .7;
            }
        }
        
    
    }
}
.m-menu{
    height:100vh;
    background:#f2f0ecee;
    backdrop-filter: blur(30px);
    transition: all .3s ease;
    position: absolute;
    z-index: 100;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    
    top:0;
    text-align: center;
    gap:2%;
    left: 0;
    padding:25px;
    padding-top:90px;
}
.nav-items-m{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height:70px;
    flex:1;
    width:100%;
    gap:10%;
    @include media-query($small){
        gap:15%;
    }
    
    .nav-item{
        cursor: pointer;
        position:relative;
        display:flex;
        align-items: center;
        text-align: center;
        font-size:10pt;
        &.active{
            span{
                opacity: 1;
                &::after{
                    transform: scaleX(1);
                }
            }
        }
        span{
            transition:all .3s ease;
            opacity: .5;
            user-select: none;
            &:hover{
             & + img{
                opacity: .7;
            }
            &::after{
                transform: scaleX(1);
            }
        }
            position: relative;
            z-index: 10;
        &::after{
            transition:all .3s ease;
            content:'';
            position:absolute;
            transform:scaleX(0);
            transform-origin: 0%;
            bottom:-1px;
            left:0;
            width:100%;
            height:2px;
            background:#000;
        }
        }
        img{
            z-index: 0;
            transition:all .3s ease;
            position:absolute;
            width:200px;
            top:0%;
            opacity: 0;
            left:50%;
            top:0%;
            transform:translate(-50%, -20%);
        }
        
        &.active{
            img{
                opacity: .7;
            }
        }
        
    
    }
}
.header{
    
    max-height:70px;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    // box-shadow: 0px 22px 22px -22px rgba(0,0,0,0.4);
    transition:all .3s ease;
    display:flex;
    padding:0% 20%;
    backdrop-filter: blur(15px);
    align-items: center;
    z-index: 1000;
    &.scrolled{
      .nav-item{
        color:#000 !important;
        &::after{
            background:#000 !important;
        }
      }
    }
    @include media-query($small){
        padding:0% 15px;
    }
    .header-image{
        display:flex;
        align-items: center;
        span{
            margin-left:10px;
        }
        img{
            padding-left:10px;
            height:100%;
        }
    }
}
</style>
