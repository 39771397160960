<template>
  <div ref="mapContainer" class="map-container" :class="{'saturate':isSaturate}"></div>
</template>
<script>
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGV4aG9uc2EiLCJhIjoiY2luejFndTViMThsNnUya2o5bThpemRsaSJ9.GFlYLJmm5XmM-cGc57UH9g";

export default {
  name: "map",
  data() {
    return {
        map:null,
        isSaturate:false
    };
  },
  mounted() {
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/mapbox/satellite-v9?optimize=true", // Replace with your preferred map style
      center: [11.328550, 43.790755],
      zoom: 6,
      cooperativeGestures: true,
      pitch: 45,
     // dragPan: false,
    });
    // map.scrollZoom.disable();
    const start = {
    center: [11.328520, 43.780650],
    zoom: 1,
    pitch: 0,
    bearing: 0
    };
    const end = {
    center: [11.328220, 43.779660],
    zoom: 16.5,
    pitch: 0,
    bearing: 0
    };
    
    function rotateCamera(timestamp) {
    // clamp the rotation between 0 -360 degrees
    // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
    map.rotateTo((timestamp / 100) % 360, { duration: 0 });

    // Request the next frame of the animation.
    requestAnimationFrame(rotateCamera);
    }

    map.on('load', () => {
      map.flyTo({
    ...end,
    
    duration: 6000, // Animate over 12 seconds
    essential: true // This animation is considered essential with
    //respect to prefers-reduced-motion
    });
// Start the animation.
// setTimeout(()=>{
  // setTimeout(()=>{
  //   rotateCamera(0);
  // },6000)
   
// },6000)

 
// Add 3D buildings and remove label layers to enhance the map
// const layers = map.getStyle().layers;
// for (const layer of layers) {
// if (layer.type === 'symbol' && layer.layout['text-field']) {
// // remove text labels
// // map.removeLayer(layer.id);
// }
// }
 
// map.addLayer({
// 'id': '3d-buildings',
// 'source': 'composite',
// 'source-layer': 'building',
// 'filter': ['==', 'extrude', 'true'],
// 'type': 'fill-extrusion',
// 'minzoom': 15,
// 'paint': {
// 'fill-extrusion-color': '#aaa',
 
// // use an 'interpolate' expression to add a smooth transition effect to the
// // buildings as the user zooms in
// 'fill-extrusion-height': [
// 'interpolate',
// ['linear'],
// ['zoom'],
// 15,
// 0,
// 15.05,
// ['get', 'height']
// ],
// 'fill-extrusion-base': [
// 'interpolate',
// ['linear'],
// ['zoom'],
// 15,
// 0,
// 15.05,
// ['get', 'min_height']
// ],
// 'fill-extrusion-opacity': 0.6
// }
// });
});
//     map.on("wheel", event => {
//     if (event.originalEvent.ctrlKey) {
//         return;
//     }

//     if (event.originalEvent.metaKey) {
//         return;
//     }

//     if (event.originalEvent.altKey) {
//         return;
//     }

//     event.preventDefault();
// });
    // new mapboxgl.Marker({color:'#6d4139'}).setLngLat([11.328520, 43.780650]).addTo(map);


  setTimeout(()=>{this.isSaturate = true}, 3000)
    this.map = map;
  },
  unmounted() {
    this.map.remove();
    this.map = null;
    }
};
</script>
<style lang="scss" scoped>
.map-container {
  filter:saturate(0);
  flex: 1;
  color:#6d4139;
  opacity: .3;
  height:100vh;
  width:100%;
  margin-bottom:55px;
  transition:all 2s ease;
  &.saturate{
    opacity: 1;
    filter:saturate(1);
  }
  @include media-query($small){
    height:100vh;
}
}
</style>
