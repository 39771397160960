<template>
    <div  class="page-s">
        <div  class="content">
            
            <!-- <div class="bg fadeIn animated" style="animation-delay:1s"></div> -->
            <!-- <img class="flight" src="@/assets/flight-path2.png" alt=""> -->
            <!-- <div class="home">
                
                <h1  class="fadeIn animated amalfi h-title2" style="animation-delay:1s;font-weight: 300;">Travel</h1>
                <h1  ref="ciao" class="fadeIn animated amalfi h-title" style="animation-delay:.5s; font-weight: 300;">Travel</h1>
            </div> -->
            <section style="height:100vh; position: relative;">
                <div class="overlay2"></div>
                <!-- <transition enter-active-class="fadeIn" leave-active-class="fadeOut"> -->
                <div  class="meetimg">We'll Meet Here 
                    <transition enter-active-class="fadeInLeft" leave-active-class="fadeOutDown">
                    <img v-if="showhand" class="animated-med" src="@/assets/SVG/hand.svg" />
                    </transition>
                    <br><a target="_blank" href="https://www.google.com/maps/place/Villa+Gamberaia/@43.7806937,11.3259612,17z/data=!3m1!4b1!4m11!3m10!1s0x132a54c70c703a4b:0x1d93205efd2650c7!5m4!1s2024-06-06!2i4!4m1!1i2!8m2!3d43.7806899!4d11.3285361!16s%2Fm%2F04j9m5x?entry=ttu"><v-btn style="box-shadow:0px 10px 20px -10px rgba(0,0,0,0.4); margin-top:25px;" small>Open in Maps</v-btn></a></div>
                <!-- <img  class="meetimg animated" src="@/assets/meet.png"/> -->
                <!-- </transition> -->
                <Map />
            </section>
        <section>
                <div class="page-width" style="padding:5% 0px;">
                    <div v-motion-slide-visible-right="!isMobile"  class="section1 ">
                        <div class="sec-img">
                        <img src="@/assets/byair.webp" alt="">
                        </div>
                        <div class="sec-body" style="padding:5%;">
                            <h2 class="amalfi letterpress">By Air</h2>
                        <span class="sackers">The Villa is a ~50 minute taxi from the Florence Airport.  We recommend getting into the area at least a day early to fight jetlag!  </span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <section>
                <div class="page-width" style="padding:5% 0px;">
                    <div v-motion-slide-visible-right="!isMobile" style="margin-top:55px;"  class="section1 reverse">
                        <div class="sec-img">
                            <img src="@/assets/bytrain.webp" alt="">
                        </div>
                        <div class="sec-body" style="padding:5%;">
                            <h2 class="amalfi letterpress">By Train</h2>
                        <span class="sackers">Italy has a great high speed train system and can be reached in a lot of ways!  We took the italo line and had a greate experience for a great price! </span><br><br>
                        <a href="https://www.italotreno.it/en"><v-btn compact>Italo</v-btn></a>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            
            <section>
                <div style="height:300px;"></div>
            </section>

    
        </div>
    </div>
    </template>
    <script>
    import Map from '../components/Map.vue';
import Map2 from '../components/Map2.vue';
import Map3 from '../components/Map3.vue';
import ScrollOut from "scroll-out";
    export default {
        name:'Event',
        components:{
        Map,
        Map2,
        Map3
    },
        data(){
            return {
                showhand:false,
                isLoading:false,
                aimX:null,
                aimY:null,
                showmeet:false
            }
        },
        computed:{
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        },
        mounted(){
            // ScrollOut({
            //     targets: ".meetimg",
            //     cssProps: true
            // })
            setTimeout(()=>{
                this.showmeet = true;
            },5000);
            setTimeout(()=>{
                this.showhand = true;
            },5600);
            // function requestDeviceOrientation () {
            // if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            // DeviceOrientationEvent.requestPermission()
            // .then(permissionState => {
            // if (permissionState === 'granted') {
            // window.addEventListener('deviceorientation', () => {});
            // }
            // })
            // .catch(console.error);
            // } else {
            // // handle regular non iOS 13+ devices
            // console.log ("not iOS");
            // }
            // }
            // requestDeviceOrientation();
           
            // let currentX = 0;
            // let currentY = 0;
            // this.aimX = 0;
            // this.aimY = 0;
            // let speed = 0.1;
            // let windowHeight = 0;
            // if(this.$refs.ciao == null) return;
            // let {width, height} = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect();
            
            // window.addEventListener('resize', ()=>{
            //     height = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect().height;
            //     windowHeight = window.innerHeight;
            // });
            // const animate = () => {
            //     currentX += (this.aimX - currentX) * speed;
            //     currentY += (this.aimY - currentY) * speed;
            //     if(this.$refs.ciao == null)return;
            //     this.$refs.ciao.style.backgroundPosition = `${currentX}px ${currentY - windowHeight/2}px`
            //     requestAnimationFrame(animate)
            // }
            // animate();
            // window.addEventListener('mousemove', (e)=>{
                
            //     this.aimX = e.pageX/34;
            //     this.aimY = e.pageY/34
               
            // })
            
            
            // if(window.DeviceOrientationEvent) {
            //     window.addEventListener('deviceorientation', (event) => {
                    
            //     //let alpha = event.alpha;
            //      this.aimX = event.gamma /2;
            //      this.aimY = 150 + event.beta/2;
            // // let beta = event.beta;
            // // let gamma = event.gamma;
            //     // Do something
            //     });
            // }
        },
    
    }
    </script>

    <style scoped lang="scss">
    .meetimg{
        
        position:absolute;
        top:50%;
        left:calc(50%);
        transform:translateX(calc(-100% - 20px)) translateY(calc(-50% - 13px));
        width:400px;
        z-index: 10;
        font-size:35pt;
        color:#fff;
        font-family: vogue;
        width:500px;
        @include media-query($small){
            width: 156px;
             font-size: 38pt;
            transform:translateX(calc(-100% - 20px)) translateY(calc(-50% + 40px));
        }
        img{
            position:absolute;
            width:192px;
            top: -65px;
            right: -6px;
        }
    }
    .overlay2{
        background:linear-gradient(90deg, #6d4139b7, #5e525375);
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 3;
    }
    
.section1{
        display:flex;
        align-items: center;
        gap:5%;
        @include media-query($small){
            flex-wrap: wrap;
        }
        &.reverse{
            flex-direction: row-reverse;
            @include media-query($small){
                flex-direction: row;
            }
        }
        .sec-img{
            @include media-query($small){
                flex-basis: 100%;
            }
            flex:1;
            // max-width:400px;

            
            position: relative;
            img{
                @include media-query($small){
                    max-height:300px;
                }
                box-shadow: 0px 6px 20px -5px rgba(0,0,0,0.3);
                height:100%;
                width:100%;
                object-fit: cover;
            }
        }
        .sec-body{
            flex:1;
             
             @include media-query($small){
                margin-bottom:55px;
            }
        h2{
            font-size: 34pt;
            color:#5e5253;
            @include media-query($small){
                font-size: 24pt;
                text-align: center;
            }
            
        }
        span{

        }
        .color-p{
            margin:20px 0px;
            display:flex;
            align-items: center;
            gap:20px;
            .color{
                border:solid 2px #5e5253;
                height:65px;
                width:65px;
                border-radius: 100px;
                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.2);
                &.pink1{
                    background:#733C50;
                }
                &.pink2{
                    background:#9D6371;
                }   
                &.pink3{
                    background:#DDB5BE;
                }
                &.pink4{
                    background:#CCB2A3;
                }
                &.pink5{
                    background:#F2EEEB;
                }
            }
        }
    }
    }
    .flight{
        position:absolute;
        width:100%;
        padding:30%;
        transform:translateY(-30%) translateX(20%);
        box-sizing: border-box;
        opacity: .5;

    }
    .bg{
        &::before{
            content:'';
            height: 100%;
            position:absolute;
            z-index: 3;
            top:0;
            left:0;
            width:100%;
            background:linear-gradient(180deg, #00000080, transparent);
        }
        display:none;
        background-image: url('../assets/venue/wide2.webp');
        background-size:cover;
        background-position: center;
        position: absolute;
        
        left:0;
        height:calc(100vh - 70px);
        width:100%;
        pointer-events: none;
        z-index: 1;
    }
   
    .h-title{
        position: relative;
        width: 100%;
        text-align: center;
        background: -webkit-linear-gradient(-90deg, #e7ddde, #645657);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
       // color:#a69597;
        //text-shadow: 1px 1px 0px #fff, -1px -1px 1px #5e5253;
        z-index: 10;
        // color:#fff;
        font-size:12vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            font-size:12vw;
        }
        user-select: none;
    
    }
    .h-title2{
        position: absolute;
    
        background-size: cover;
        background-position: center;
        z-index: 7;
        text-shadow: 3px 4px 5px rgba(0,0,0,0.1);
        color:transparent;
        font-size:12vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            text-shadow: 3px 6px 5px rgba(0, 0, 0, 0.1);
            font-size:12vw;
        }
        user-select: none;
    
    }
    .content{
        position: relative;
        min-height:100%;
        width:100%;
        overflow-x: hidden;
    }
    .box{
        height:1000px;
        width:1000px;
    }
    .home{
        max-height:calc(100vh - 70px);
    padding-top:000px;
    width:100%;
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
        
    }
    .timeframe{
        display:flex;
        flex-direction: column;
        align-items: center;
        .date{
            font-size:52pt;
            @include media-query($small){
                font-size:38pt;
            }
            sup{
                font-size:.5em
            }
        }
        .time{
            display:flex;
            align-items: center;
            width: 581px;
            transform: translateX(147px);
            @include media-query($small){
                width: 100%;
                transform: translateX(42px);
            }
            sup{
                font-size:.5em
            }
            i{
                color:#000;
                font-size:10pt;
                display:flex;
                align-items: center;
                justify-content: center;
                height:25px;
                width:25px;
            }
            .timestamp{
                margin-right:10px;
                color:#645657;
                font-size:17pt;
                width:103px;
            }
            .event{
                margin-left:15px;
                color:#645657;
                font-size:22pt;
                @include media-query($small){
                    line-height:1.3em;
                    font-size:13pt;
                }
            }
        }
        img{
            margin:100px 0px;
            width:15%;
            @include media-query($small){
                width:35%;
            }
        }
        .line{
            width:1px;
            height:45px;
            background:#645657;
            transform: translateX(-21px);
        }
    }
    </style>