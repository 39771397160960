import jwt_decode from "jwt-decode";

export default {
  state: {
    user: {}
  },
  mutations: {
    SET_USER(state, payload) {
        var user = payload;
        state.user = user;
      }
  },
  getters: {
    GET_USER(state){
      return state.user;
    }
  },
  actions: {
   setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    setToken(context, payload) {
      var decoded = jwt_decode(payload);
      context.commit("SET_USER", decoded);
    }
  }
};
