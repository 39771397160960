<template>
	<button
		:type="type"
		class="btn-b sackers"
		:class="{
			'small-d': small,
			'mini-d': mini,
			'disabled-d': disabled,
			'large-d': large,
			'block-d': block,
			'text-d': text,
			'outlined-d': outlined,
			'large-d': large,
			'compact-d': compact,
			'inline-d': inline,
			'dark-d': dark,
			'loading-d': loading,
			fab: fab,
			secondary: secondary
		}"
	>
		<i class="fa pre" v-if="prependIcon != ''" :class="prependIcon"></i>
		<slot v-if="!loading"></slot>
		<v-spinner v-else :size="2" :width="20" color="#fff"></v-spinner>
		<i class="fa post" v-if="appendIcon != ''" :class="appendIcon"></i>
	</button>
</template>
<script>
export default {
	name: "imdex-button",
	props: {
		prependIcon: {
			type: String,
			default: ""
		},
		appendIcon: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "submit"
		},

		block: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},

		large: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},

		compact: {
			type: Boolean,
			default: false
		},
		mini: {
			type: Boolean,
			default: false
		},
		inline: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: false
		},

		fab: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		secondary: {
			type: Boolean,
			default: false
		}
	},
	methods: {}
};
</script>
<style lang="scss">
.btn-b {
	outline: none;
	border: none;
	text-align: center;
	padding: 12px 24px;
	
	display: inline-block;
	font-size: 16px;
	border-radius: 0px;
	transition: all 0.1s ease;
	box-sizing: border-box;

	white-space: nowrap;
	background: $imdex-highlight;
	&:hover {
		cursor: pointer;
		background: $imdex-accent;
		color: #fff;
	}

	color: #fff;
	&.fab {
		height: 50px;
		width: 50px;
		border-radius: 50px;
		align-items: center;
		justify-content: center;
		padding: 0 !important;
		font-weight: 500;
		display: inline-flex;
	}
	&.secondary {
		border-color: $imdex-secondary;
		background: $imdex-secondary;

		&:hover {
			border-color: $imdex-secondary;
			background: darken($imdex-secondary, 5%);
		}
	}
	i {
		color: #fff;
		&.pre {
			margin-right: 10px;
		}
		&.post {
			margin-left: 10px;
		}
	}

	&.outlined-d {
		border: solid 2px $imdex-highlight;
		background: transparent;
		color: $imdex-highlight;
		i {
			color: $imdex-highlight;
		}
		&:hover {
			border: solid 2px $imdex-accent;
			background: $imdex-accent;
			color: #fff;
			i {
				color: #fff;
			}
		}
		&.secondary {
			border-color: $imdex-secondary;
			color: $imdex-secondary;
			i {
				color: $imdex-secondary;
			}
			&:hover {
				border-color: $imdex-secondary;
				background: $imdex-secondary;
				color: #fff;
			}
		}
	}

	&.text-d {
		background: transparent;
		color: $imdex-highlight;
		i {
			color: $imdex-highlight;
		}
		&:hover {
			background: $imdex-borders;
			color: #fff;
			i {
				color: #fff;
			}
		}
		&.dark-d {
			color: #fff;
			i {
				color: #fff;
			}
			&:hover {
				background: #ffffff30;
				color: #fff;
				i {
					color: #fff;
				}
			}
		}
		&.secondary {
			color: $imdex-secondary;
			&:hover {
				background: $imdex-secondary;
				color: #fff;
			}
		}
	}
	&.compact-d {
		padding: 7px 32px;
		font-size: 12px;
		&.fab {
			height: 35px;
			width: 35px;
		}
	}
	&.mini-d {
		padding: 4px 7px;
		font-size: 12px;
		&.fab {
			height: 25px;
			width: 25px;
		}
	}
	&.large-d {
		padding: 30px 24px;
		font-size: 24px;
	}
	&.inline-d {
		padding: 2px 4px;
		font-size: 12pt;
	}

	&.block-d {
		padding: 10px 40px;
		width: 100%;
		text-align: center;
	}
	&.loading-d {
		background: #000 !important;
		color: #00000030;
		&.secondary {
			background: $imdex-secondary !important;
			color: #fff;
		}
		&.dark-d {
			background: #00000030 !important;
			color: #ffffff80;
		}
	}
	&.disabled-d {
		pointer-events: none;
		background: $imdex-light !important;
		&.text-d {
			background: transparent !important;
		}
		color: #00000030;
		&.dark-d {
			background: #00000030 !important;
			color: #ffffff80;
		}
	}
}
</style>
