
import Vuex from "vuex";
import userStore from './user';



let store = new Vuex.Store({
  modules: {
   userStore,
  },
  state: {
  },
  getters: {},
  mutations: {
    
  },
  actions: {
    
    
  }
});
console.log(store);
store.allState = Reflect.ownKeys(store.state)
store.allGetters = Reflect.ownKeys(store.getters)
store.allActions = Reflect.ownKeys(store._actions);
store.allMutations = Reflect.ownKeys(store._mutations)

export default store;
