<template>
    <div  class="page-s">
        <div  class="content">
            
            <!-- <div class="bg fadeIn animated" style="animation-delay:1s"></div> -->
            
            <div class="home">
                <h1  class="fadeIn animated amalfi h-title2" style="animation-delay:1s;font-weight: 300;">Accomodations</h1>
                <h1  ref="ciao" class="fadeIn animated amalfi h-title" style="animation-delay:.5s; font-weight: 300;">Accomodations</h1>
            </div>
            <section v-if="false">
                <div class="page-width">
                    <div v-motion-slide-visible-right="!isMobile"  class="section1">
                        <div class="sec-img">
                            <img src="@/assets/flowers.png" alt="">
                        </div>
                        <div class="sec-body">
                            <h2 class="amalfi letterpress">Our Color Pallette</h2>
                        <div class="color-p">
                            <div class="color pink1"></div>
                            <div class="color pink2"></div>
                            <div class="color pink3"></div>
                            <div class="color pink4"></div>
                            <div class="color pink5"></div>
                        </div>
                        <span class="sackers">Welcome to our 'Enchanted Elegance' wedding color scheme, where romance meets sophistication. Here, we celebrate the harmonious blend of pinks, maroons, beiges, champagnes, and ivories, creating a palette that exudes warmth and love. The softness of pink symbolizes tender affection, while the depth of maroon adds a touch of passion and strength. Beige and champagne introduce a neutral elegance, grounding the scheme with their earthy, subtle sophistication. </span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <section>
                <Flicking ref="flicking" :plugins="plugins" :options="{ circular: !isMobile }">
                    <div class="image3" ><img src="@/assets/room1.jpg" alt=""></div>
                    <div class="image3" ><img src="@/assets/room2.jpg" alt=""></div>
                    <div class="image3" ><img src="@/assets/room3.jpg" alt=""></div>
                    <div class="image3" ><img src="@/assets/room4.jpg" alt=""></div>
                    <div class="image3" ><img src="@/assets/room5.jpg" alt=""></div>
                    <div class="image3" ><img src="@/assets/room6.jpg" alt=""></div>
                    <template #viewport>
                        <div class="flicking-pagination"></div>
                    </template>
                </Flicking>
            </section>
            <section>
                <div class="page-width">
                    <div v-motion-slide-visible-right="!isMobile"  class="section1 reverse">
                        
                        <div class="sec-body">
                            
                            <h2 class="amalfi letterpress">The 3 day Stay</h2>
                        <span class="sackers">The Villa Gamberaia is BIG! We will have room for everyone to stay with us at the Villa! It will be tight but we hope it will bring us together and can have a great time.  You are more than welcome to book your own accomodations but we urge you to take a load off and enjoy our place with us.  We have thought out the rooms and placed people according to what we think best.  Please let us know your plans ASAP so that we can plan accordingly!
                        </span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
 
            <section>
                <div style="height:300px;"></div>
            </section>

    
        </div>
    </div>
    </template>
    <script>
    
    import Flicking from "@egjs/vue3-flicking";
    import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
    import "@egjs/flicking-plugins/dist/pagination.css";
    const plugins = [new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: false }), new Pagination({type:'bullet'})];
    export default {
        name:'Event',
        components:{
            Flicking
        },
        data(){
            return {
                isLoading:false,
                aimX:null,
                aimY:null,
                interval:null,
                plugins
            }
        },
        computed:{
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
            },
        },
        beforeDestroy() {
           // clearInterval(this.interval);
        },
        mounted(){
            
            // function requestDeviceOrientation () {
            // if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            // DeviceOrientationEvent.requestPermission()
            // .then(permissionState => {
            // if (permissionState === 'granted') {
            // window.addEventListener('deviceorientation', () => {});
            // }
            // })
            // .catch(console.error);
            // } else {
            // // handle regular non iOS 13+ devices
            // console.log ("not iOS");
            // }
            // }
            // requestDeviceOrientation();
           
            // let currentX = 0;
            // let currentY = 0;
            // this.aimX = 0;
            // this.aimY = 0;
            // let speed = 0.1;
            // let windowHeight = 0;
            // if(this.$refs.ciao == null) return;
            // let {width, height} = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect();
            
            // window.addEventListener('resize', ()=>{
            //     height = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect().height;
            //     windowHeight = window.innerHeight;
            // });
            // // this.interval = setInterval(()=>{
            // //     if(this.$refs.flicking){
            // //         this.$refs.flicking.next().catch(err => {
            // //         if (err) return;
            // //         throw err;
            // //     });;
            // //     }
                
            // // },5000);
            // const animate = () => {
            //     currentX += (this.aimX - currentX) * speed;
            //     currentY += (this.aimY - currentY) * speed;
            //     if(this.$refs.ciao == null)return;
            //     this.$refs.ciao.style.backgroundPosition = `${currentX}px ${currentY - windowHeight/2}px`
            //     requestAnimationFrame(animate)
            // }
            // animate();
            // window.addEventListener('mousemove', (e)=>{
                
            //     this.aimX = e.pageX/34;
            //     this.aimY = e.pageY/34
               
            // })
            
            
            // if(window.DeviceOrientationEvent) {
            //     window.addEventListener('deviceorientation', (event) => {
                    
            //     //let alpha = event.alpha;
            //      this.aimX = event.gamma /2;
            //      this.aimY = 150 + event.beta/2;
            // // let beta = event.beta;
            // // let gamma = event.gamma;
            //     // Do something
            //     });
            // }
        },
    
    }
    </script>

    <style scoped lang="scss">
    
    .image3{
        height:50vh;
        width:25%;
        position:relative;
        padding:1%;
        
        @include media-query($small){
            height:50vh;
            width:100%;
        }
        img{
            box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.3);
            object-fit: cover;
            height:100%;
            width:100%;
        }
    }
    section{
        // margin:5%;
    }
    .section1{
        display:flex;
        align-items: center;
        gap:5%;
        @include media-query($small){
            flex-wrap: wrap;
        }
        &.reverse{
            flex-direction: row-reverse;
            @include media-query($small){
                flex-direction: row;
            }
        }
        .sec-img{
            @include media-query($small){
                flex-basis: 100%;
            }
            flex:1;
            // max-width:400px;

            
            position: relative;
            img{
                @include media-query($small){
                    max-height:300px;
                }
                box-shadow: 0px 6px 20px -5px rgba(0,0,0,0.3);
                height:100%;
                width:100%;
                object-fit: cover;
            }
        }
        .sec-body{
            flex:1;
             
             @include media-query($small){
                margin-bottom:55px;
                padding:15px;
            }
        h2{
            font-size: 34pt;
            color:#5e5253;
            @include media-query($small){
                font-size: 24pt;
                text-align: center;
            }
            
        }
        span{

        }
        .color-p{
            margin:20px 0px;
            display:flex;
            align-items: center;
            gap:20px;
            .color{
                border:solid 2px #5e5253;
                height:65px;
                width:65px;
                @include media-query($small){
                    height:12vw;
                    width:12vw;
                }
                border-radius: 100px;
                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.2);
                &.pink1{
                    background:#733C50;
                }
                &.pink2{
                    background:#9D6371;
                }   
                &.pink3{
                    background:#DDB5BE;
                }
                &.pink4{
                    background:#CCB2A3;
                }
                &.pink5{
                    background:#F2EEEB;
                }
            }
        }
    }
    }


    .bg{
        &::before{
            content:'';
            height: 100%;
            position:absolute;
            z-index: 3;
            top:0;
            left:0;
            width:100%;
            background:linear-gradient(180deg, #00000080, transparent);
        }
        display:none;
        background-image: url('../assets/venue/wide2.webp');
        background-size:cover;
        background-position: center;
        position: absolute;
        
        left:0;
        height:calc(100vh - 70px);
        width:100%;
        pointer-events: none;
        z-index: 1;
    }
   
    .h-title{
        position: relative;
        width: 100%;
        text-align: center;
         background: -webkit-linear-gradient(-90deg, #e7ddde, #645657);
        // background-image:url('@/assets/flowers.png');
        // background-size: contain;
        // background-position: center center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
       // color:#a69597;
        //text-shadow: 1px 1px 0px #fff, -1px -1px 1px #5e5253;
        z-index: 10;
        // color:#fff;
        font-size:6vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            font-size:10vw;
        }
        user-select: none;
    
    }
    .h-title2{
        position: absolute;
    
        background-size: cover;
        background-position: center;
        z-index: 7;
        text-shadow: 3px 4px 5px rgba(0,0,0,0.1);
        color:transparent;
        font-size:6vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            text-shadow: 3px 6px 5px rgba(0, 0, 0, 0.1);
            font-size:10vw;
        }
        user-select: none;
    
    }
    .content{
        position: relative;
        min-height:100%;
        width:100%;
        overflow: hidden;
    }
    .box{
        height:1000px;
        width:1000px;
    }
    .home{
        max-height:calc(100vh - 70px);
    padding-top:000px;
    width:100%;
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
        
    }
    .timeframe{
        display:flex;
        flex-direction: column;
        align-items: center;
        .date{
            font-size:52pt;
            @include media-query($small){
                font-size:38pt;
            }
            sup{
                font-size:.5em
            }
        }
        .time{
            display:flex;
            align-items: center;
            width: 581px;
            transform: translateX(147px);
            @include media-query($small){
                width: 100%;
                transform: translateX(42px);
            }
            sup{
                font-size:.5em
            }
            i{
                color:#000;
                font-size:10pt;
                display:flex;
                align-items: center;
                justify-content: center;
                height:25px;
                width:25px;
            }
            .timestamp{
                margin-right:10px;
                color:#645657;
                font-size:17pt;
                width:103px;
            }
            .event{
                margin-left:15px;
                color:#645657;
                font-size:22pt;
                @include media-query($small){
                    line-height:1.3em;
                    font-size:13pt;
                }
            }
        }
        img{
            margin:100px 0px;
            width:15%;
            @include media-query($small){
                width:35%;
            }
        }
        .line{
            width:1px;
            height:45px;
            background:#645657;
            transform: translateX(-21px);
        }
    }
    </style>