<template>
	<div
		@click="selectCheckbox"
		class="d-checkbox"
		:class="{ 'disabled-d': disabled, error: error, 'dark-d': dark, 'secondary-d': secondary }"
	>
		<div class="checkbox-icon">
			<i v-if="!v" class="fal fa-square"></i>
			<i v-if="v" class="fa fa-check-square"></i>
		</div>
		<label>{{ label }}</label>
	</div>
</template>
<script>
export default {
	name: "imdex-checkbox",
	props: {
		label: {
			type: String,
			default: ""
		},
		val: [String],
		value: [Boolean, Array, String],
		secondary: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		},
		errorMessageText: {
			type: String,
			default: ""
		}
	},
	methods: {
		selectCheckbox() {
			if (Array.isArray(this.value)) {
				const arr = this.value;
				if (this.v) {
					arr.splice(
						arr.findIndex((item) => item == this.val),
						1
					);
				} else {
					arr.push(this.val);
				}
				this.$emit("update:value", arr);
			} else {
				this.$emit("update:value", !this.value);
			}
		}
	},
	computed: {
		v() {
			if (Array.isArray(this.value)) {
				return this.value.indexOf(this.val) > -1;
			} else {
				return this.value;
			}
		}
	}
};
</script>
<style lang="scss">
.d-checkbox {
	position: relative;
	padding: 0px 0px;
	display: flex;
	cursor: pointer !important;
	align-items: center;
	&:active {
		.checkbox-icon {
			&::before {
				opacity: 1;
			}
		}
	}

	.checkbox-icon {
		&::before {
			content: "";
			position: absolute;
			width: 41px;
			top: -11px;
			left: -12px;
			height: 41px;
			pointer-events: none !important;
			border-radius: 100%;
			background: #00000040;
			opacity: 0;
			transition: all 0.3s ease;
			z-index: 1;
		}
		position: relative;
		z-index: 2;
		margin-right: 10px;
		i {
			font-size: 20px;

			&.fa-square {
				color: $imdex-dark;
			}
			&.fa-check-square {
				color: $imdex-highlight;
			}
		}
	}
	&.secondary-d {
		.checkbox-icon {
			i {
				&.fa-check-square {
					color: $imdex-secondary;
				}
			}
		}
	}
	label {
		cursor: pointer;
        font-family: Sackers;
		user-select: none;
		color: $imdex-dark-grey;
	}
	&.error {
		.checkbox-icon {
			i {
				color: $imdex-error;
			}
		}
		label {
			color: $imdex-error;
		}
		.assistive-text {
			color: $imdex-error;
		}
	}
	&.dark-d {
		.checkbox-icon {
			i {
				color: #fff;
			}
			color: #fff;
		}
	}

	&.disabled-d {
		background: transparent;
		pointer-events: none;
		.checkbox-icon {
			i {
				color: $imdex-grey2 !important;
			}
		}
		label {
			color: $imdex-tertiary-light;
			pointer-events: none;
		}
	}
}
</style>
