<template>

    <Header />
    <div class="bg"></div>
    <div class="flowers" ref="flowers">
      <img class="roses-left" src="@/assets/roses-left.png">
        <img class="roses-right" src="@/assets/roses-right.png">
    </div>
   
    <router-view v-slot="{Component, route}">
      <transition :enter-active-class="route.meta.enterClass" :leave-active-class="route.meta.leaveClass">
        <component class="animated-med" :is="Component"></component>
      </transition>
    </router-view>
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
    <div v-if="$route.path != '/' && $route.path != '/rsvp'" :class="{'blocked':isMobile}" class="rsvp-cont animated">
      <v-btn style="box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.4);" @click="$router.push('/rsvp')" :block="isMobile" compact>RSVP</v-btn>
    </div>
    </transition>
</template>
<script>
import "../node_modules/mapbox-gl/dist/mapbox-gl.css"
import Header from './components/Header';
import ScrollOut from "scroll-out";
import _ from 'lodash';
export default {
  name:'App',
  components:{
    Header
  },
  data(){
    return {
      scrollListener:null,
      mobileListener:null
    }
  },
  mounted(){
    
    // if(this.isMobile){
    //   this.mobileListener = window.addEventListener('touchmove', this.onScroll)
    // }else{
    //   this.scrollListener = window.addEventListener('scroll', this.onScroll)
    // }

    ScrollOut({
        targets: ".flowers",
        cssProps: {
            viewportY: true,
            visibleY: true,
            elementHeight:true
        }
    })
    
  },
  methods:{
    onScroll(e){
        let y = window.scrollY;
        let h = window.innerHeight/2;
        this.$refs.flowers.style.opacity = 1 - y/h
      }
  },
  computed:{
    isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
  },
  beforeDestroy() {
      window.removeEventListener('scroll', this.scrollListener)
      window.removeEventListener('touchmove', this.mobileListener);
    },

}
</script>
<style lang="scss">
.flicking-viewport{
        overflow: visible !important;
    }
    .flicking-pagination {
  position: absolute;
  left: 0;
  bottom: -13px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.flicking-pagination-bullets,
.flicking-pagination-scroll {
  font-size: 0;
}

.flicking-pagination-scroll {
  left: 50%;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  white-space: nowrap;
  overflow: hidden;
}

.flicking-pagination-scroll .flicking-pagination-slider {
  -webkit-transition: .2s transform;
  transition: .2s transform;
}
.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-slider,
.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-bullet {
  -webkit-transition: none;
  transition: none;
}

.flicking-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgb(10 10 10 / 10%);
  cursor: pointer;
  font-size: 1rem;
}

.flicking-pagination-scroll .flicking-pagination-bullet {
  vertical-align: middle;
  position: relative;
  -webkit-transition: .2s transform;
  transition: .2s transform;
}

.flicking-pagination-bullet-active {
  background-color: #fff;
}

.flicking-pagination-scroll .flicking-pagination-bullet {
  vertical-align: middle;
  position: relative;
  -webkit-transition: .2s transform,.2s left;
  transition: .2s transform,.2s left;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}

.flicking-pagination-scroll .flicking-pagination-bullet-active {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.flicking-pagination-scroll .flicking-pagination-bullet-prev,
.flicking-pagination-scroll .flicking-pagination-bullet-next {
  -webkit-transform: scale(0.66);
      -ms-transform: scale(0.66);
          transform: scale(0.66);
}

.flicking-pagination-scroll .flicking-pagination-bullet-prev2,
.flicking-pagination-scroll .flicking-pagination-bullet-next2 {
  -webkit-transform: scale(0.33);
      -ms-transform: scale(0.33);
          transform: scale(0.33);
}

</style>

<style lang="scss">
.flowers[data-scroll]{
  position:absolute;
  overflow: hidden;
  height:100vh;
  width:100%;
  top:0;
  left:0;
  pointer-events: none;
  opacity: var(--visible-y);
}
.bg{
  z-index: 0;
  pointer-events: none;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-image:url('./assets/paper2.webp');
  background-size:cover;
  background-position: center center;
}
.page-s{
  // position:absolute;
  width:100%;
  max-width:100vw;
  padding-top:0px;
  top:0;
  left:0;
  overflow-x: hidden;
}
.router-view{
  padding-top:70px;
  min-height:100vh;
  

  position:relative;
 
  
}
html{
  overflow-x: hidden !important;
}
#app{
  overflow-x: hidden;
  max-width:100vw;
  overflow: auto;
  // background-image:url('./assets/paper2.webp');
  // background-size: 100% auto;
  // background-repeat: repeat-y;
  // @include media-query($small){
  //   background-size: 100% 100vh;
  //   background-repeat: no-repeat;
  //   background-attachment: scroll !important;
  // }
  &::before{
    content:'';
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    
    background-repeat: repeat;

  filter: saturate(0) brightness(1);
  background-size:cover;
  }
  
}
.roses-left{
  position:absolute;
  left:0px;
  opacity: .4;
  height:100vh;
  top:3%;
  @include media-query($small){
    object-fit: cover;
    object-position: right;
    width:50%;
  }
}
.roses-right{
  position:absolute;
  right:0px;
  height:100vh;
  opacity: .6;
  top:3%;
  @include media-query($small){
    object-fit: cover;
    object-position: left;
    width:50%;
  }
}
.rsvp-cont{
  position:fixed;
  bottom:25px;
  padding-right:25px;
  right:25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
  @include media-query($small){
    width:100%;
    padding-right:0px;
    padding:0px 25px;
    right:unset
  }

}
</style>
