<template>
    <div  class="page-s">
        <div  class="content">
            
            <!-- <div class="bg fadeIn animated" style="animation-delay:1s"></div> -->
            <div class="home">
            
                <h1  class="fadeIn animated amalfi h-title2" style="animation-delay:1s;font-weight: 300;">Event</h1>
                <h1  ref="ciao" class="fadeIn animated amalfi h-title" style="animation-delay:.5s; font-weight: 300;">Event</h1>
            </div>
            <section>
                <div class="page-width">
                    <div v-motion-slide-visible-right="!isMobile" class="timeframe">
                        <div class="date amalfi letterpress" style="color:#645657">June 3<sup>rd</sup></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">3:00<sup>pm</sup></span>
                        <span class="event sackers">Arrival
                            <span class="event2 sackers">Check in to the Villa at 3pm!  </span></span>
                        

                    </div>
                    <!-- <div class="line"></div> -->
                    <!-- <div class="time">
                        
                        <span class="timestamp sackers">5:00<sup>pm</sup></span>
                        <span class="event sackers">Refreshments<br>
                            <span class="event2 sackers">Guests will be greeted with some drinks and small bites leading up to the dinner.</span></span>

                    </div> -->
                    <div class="line"></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">7:00<sup>pm</sup></span>
                        <span class="event sackers">Rehearsal Dinner<br>
                            <span class="event2 sackers">A delicious pizza dinner followed by authentic italian gelato.</span></span>

                    </div>
                    <img src="@/assets/swirl.svg"  alt="">
                </div>
                <div v-motion-slide-visible-right="!isMobile" class="timeframe">
                        <div class="date amalfi letterpress" style="color:#645657">June 4<sup>th</sup></div>
                    
                    <div class="time">
                        
                        <span class="timestamp sackers">5:30<sup>pm</sup></span>
                        <span class="event sackers">Ceremony<br>
                            <span class="event2 sackers">Here comes the bride babyyyy</span></span>

                    </div>
                    <div class="line"></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">7:00<sup>pm</sup></span>
                        <span class="event sackers">Reception<br>
                            <span class="event2 sackers">Dinner is served! Fresh local pastas & drinks  leading up to a wonderful Florentine steak main course.  Have you ever had italian wedding cake? well you will...</span></span>

                    </div>
                    <img src="@/assets/swirl.svg"  alt="">
                </div>
                <div v-motion-slide-visible-right="!isMobile" class="timeframe">
                        <div class="date amalfi letterpress" style="color:#645657">June 5<sup>th</sup></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">10:00<sup>am</sup></span>
                        <span class="event sackers">Brunch<br>
                            <span class="event2 sackers">Slide to the left. slide to the right. brunch time!</span></span>

                    </div>
                    <div class="line"></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">12:00<sup>pm</sup></span>
                        <span class="event sackers">Drinks & Chill<br>
                            <span class="event2 sackers">Keep that buzzin at the backyard block party by the bar!</span></span>

                    </div>
                    <div class="line"></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">2:00<sup>pm</sup></span>
                        <span class="event sackers">Pool<br>
                            <span class="event2 sackers">We like short shorts!</span></span>

                    </div>
                    <img src="@/assets/swirl.svg"  alt="">
                </div>
                <div v-motion-slide-visible-right="!isMobile" class="timeframe">
                        <div class="date amalfi letterpress" style="color:#645657">June 6<sup>th</sup></div>
                    <div class="time">
                        
                        <span class="timestamp sackers">11:00<sup>am</sup></span>
                        <span class="event sackers">Checkout<br>
                            <span class="event2 sackers">damn, its time to go.  We say our goodbyes and fond farewells as the clock strikes 11.</span></span>

                    </div>
                    
                    <img src="@/assets/swirl.svg"  alt="">
                </div>
                
                </div>
                
            </section>
            <section>
                <div class="page-width">
                    <div v-motion-slide-visible-right="!isMobile"  class="section1 ">
                        <div class="sec-img">
                            <img src="@/assets/pizzacart.jpg" alt="">
                        </div>
                        <div class="sec-body">
                            <h2 class="amalfi letterpress">Rehearsal Dinner</h2>
                        <span class="sackers">Our rehearsal dinner will begin at 7:00pm on June 3rd.  After everyone arrives and gets situated, dinner will be provided by whitedog pizza.  A portable pizza oven sits on the back of a converted truck that will make pizza for all our guests.  A gelato cart will then be ready for desert! </span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <section>
                <div class="page-width">
                    <div v-motion-slide-visible-right="!isMobile"  class="section1 reverse">
                        <div class="sec-img">
                            <img src="@/assets/ceremonyarea.jpg" alt="">
                        </div>
                        <div class="sec-body">
                            <h2 class="amalfi letterpress">Wedding Day</h2>
                        <span class="sackers">The big day!  Our day will start with everyone waking up and grabbing a quick bite to eat at the kitchen.  The preparations will then begin for the ceremony!  The ceremony will begin at 5:30 after some drinks and small bites. Our dinner will be catered by the local Il Sorriso.  The courses will consist of pastas, and finishing with a tuscan favorite, florentine steak. After dinner, we will move the party inside to the reception with a great DJ nd dancing!  Bring your best shoes!</span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <section>
                <div class="page-width">
                    <div v-motion-slide-visible-right="!isMobile"  class="section1 ">
                        <div class="sec-img">
                            <img src="@/assets/brunch.jpg" alt="">
                        </div>
                        <div class="sec-body">
                            <h2 class="amalfi letterpress">Post Wedding Brunch</h2>
                        <span class="sackers">The day after the wedding will be met with a beautiful brunch provided again by Il Sorriso.  Our brunch will be a great closing event where we can relax and reflect on the previous days events.  When everyone has eaten and napped, we will have a pool day to chill out before heading out the next day. </span>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <section>
                <div style="height:300px;"></div>
            </section>

    
        </div>
    </div>
    </template>
    <script>
    export default {
        name:'Event',
        data(){
            return {
                isLoading:false,
                aimX:null,
                aimY:null
            }
        },
        mounted(){

        },
        computed:{
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        }
    
    }
    </script>
    <style scoped lang="scss">
    section{
        margin:5%;
    }
    .section1{
        display:flex;
        align-items: center;
        gap:5%;
        @include media-query($small){
            flex-wrap: wrap;
        }
        &.reverse{
            flex-direction: row-reverse;
            @include media-query($small){
                flex-direction: row;
            }
        }
        .sec-img{
            @include media-query($small){
                flex-basis: 100%;
            }
            flex:1;
            // max-width:400px;

            
            position: relative;
            img{
                @include media-query($small){
                    max-height:300px;
                }
                box-shadow: 0px 6px 20px -5px rgba(0,0,0,0.3);
                height:100%;
                width:100%;
                object-fit: cover;
            }
        }
        .sec-body{
            flex:1;
             
             @include media-query($small){
                margin-bottom:55px;
            }
        h2{
            font-size: 34pt;
            color:#5e5253;
            @include media-query($small){
                font-size: 24pt;
                text-align: center;
            }
            
        }
        span{

        }
        .color-p{
            margin:20px 0px;
            display:flex;
            align-items: center;
            gap:20px;
            .color{
                border:solid 2px #5e5253;
                height:65px;
                width:65px;
                border-radius: 100px;
                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.2);
                &.pink1{
                    background:#733C50;
                }
                &.pink2{
                    background:#9D6371;
                }   
                &.pink3{
                    background:#DDB5BE;
                }
                &.pink4{
                    background:#CCB2A3;
                }
                &.pink5{
                    background:#F2EEEB;
                }
            }
        }
    }
    }
    .bg{
        &::before{
            content:'';
            height: 100%;
            position:absolute;
            z-index: 3;
            top:0;
            left:0;
            width:100%;
            background:linear-gradient(180deg, #00000080, transparent);
        }
        display:none;
        background-image: url('../assets/venue/wide2.webp');
        background-size:cover;
        background-position: center;
        position: absolute;
        
        left:0;
        height:calc(100vh - 70px);
        width:100%;
        pointer-events: none;
        z-index: 1;
    }

    .h-title{
        position: relative;
        width: 100%;
        text-align: center;
        background: -webkit-linear-gradient(-90deg, #e7ddde, #645657);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
       // color:#a69597;
        //text-shadow: 1px 1px 0px #fff, -1px -1px 1px #5e5253;
        z-index: 10;
        // color:#fff;
        font-size:15vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            font-size:15vw;
        }
        user-select: none;
    
    }
    .h-title2{
        position: absolute;
    
        background-size: cover;
        background-position: center;
        z-index: 7;
        text-shadow: 3px 4px 5px rgba(0,0,0,0.1);
        color:transparent;
        font-size:15vw;
        padding-top:100px;
        @include media-query($small){
            padding-top:0;
            text-shadow: 3px 6px 5px rgba(0, 0, 0, 0.1);
            font-size:15vw;
        }
        user-select: none;
    
    }
    .content{
        position: relative;
        min-height:100%;
        width:100%;
        overflow: hidden;
    }
    .box{
        height:1000px;
        width:1000px;
    }
    .home{
        max-height:calc(100vh - 70px);
    padding-top:000px;
    width:100%;
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
        
    }
    .timeframe{
        display:flex;
        flex-direction: column;
        align-items: center;
        .date{
            font-size:52pt;
            @include media-query($small){
                font-size:38pt;
            }
            sup{
                font-size:.5em
            }
        }
        .time{
            display:flex;
            align-items: center;
            width: 581px;
            margin:15px 0px;
            transform: translateX(0);
            @include media-query($small){
                width: 100%;
                transform: translateX(0);
            }
            sup{
                font-size:.5em
            }
            i{
                color:#000;
                font-size:10pt;
                display:flex;
                align-items: center;
                justify-content: center;
                height:25px;
                width:25px;
            }
            .timestamp{
                margin-right:10px;
                color:#645657;
                font-size:17pt;
                width:96px;
            }
            .event{
                border-left:solid 1px #645657;
                margin-left:15px;
                color:#645657;
                padding-left:15px;
                font-size:22pt;
                .event2{
                    font-size:.7em; 
                    line-height: 1.3em; 
                    display: inline-block;
                }
                @include media-query($small){
                    line-height:1.3em;
                    font-size:13pt;
                }
            }
        }
        img{
            margin:100px 0px;
            width:15%;
            @include media-query($small){
                width:35%;
            }
        }
        .line{
            display:none;
            width:1px;
            height:45px;
            background:#645657;
            transform: translateX(-21px);
        }
    }
    </style>