<template>
	<svg
		:width="width"
		:height="width"
		:stroke="color"
		:viewBox="`0 0 ${width} ${width}`"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g ref="spin" :class="`spinner_${uid}`">
			<circle
				ref="circle"
				:cx="width / 2"
				:cy="width / 2"
				:r="(width / 2) * 0.8"
				fill="none"
				:stroke-width="size"
			></circle>
		</g>
	</svg>
</template>
<script>
export default {
	name: "imdex-spinner",
	props: {
		width: {
			type: [Number, String],
			default: 20
		},
		size: {
			type: [Number, String],
			default: 2
		},
		color: {
			type: String,
			default: "#002643"
		}
	},
	data() {
		return {
			uid: this.$uuid4()
		};
	},

	mounted() {
		const style2 = document.createElement("style");
		const keyFrames = `\
        @-webkit-keyframes spinner_${this.uid} {\
            0% {\
                stroke-dasharray: 0 350;\
                stroke-dashoffset: 0\
            }\
        \
            47.5% {\
                stroke-dasharray: ${this.width * 1.75} 350;\
                stroke-dashoffset: ${this.width * -0.666}\
            }\
        \
            95%,\
            100% {\
                stroke-dasharray: ${this.width * 1.75} 350;\
                stroke-dashoffset: ${this.width * -2.4583}\
            }\
        }\
        @keyframes spinner_zKoa {\
            100% {\
                transform: rotate(360deg)\
            }\
        }`;
		style2.innerHTML = keyFrames;
		document.getElementsByTagName("head")[0].appendChild(style2);
		this.$refs.circle.style.animation = `spinner_${this.uid} 1.5s ease-in-out infinite`;
		this.$refs.circle.style.strokeLinecap = "round";
		this.$refs.spin.style.animation = `spinner_zKoa 2s linear infinite`;
		this.$refs.spin.style.transformOrigin = "center";
	},
	computed: {
		r() {
			return this.size / 2;
		}
	}
};
</script>
