<template>
  <div class="page-s" >
    <div class="login">
      <div ref="droplets" class="droplets"></div>
      
      <div>
       
      <div ref="container" class="animation">
        
        <h1 v-if="showTitle" class="amalfi b-title animated-slow fadeIn"><span class="ss02">D</span>ex & Roxan<span class="ss01">a</span><br><span class="vogue upcase date">June 4, 2024</span></h1>
        
        
        <div v-if="showTitle" @click="enter" class="btn animated-slow fadeIn"><span class="upcase vogue">ENTER</span></div>
        <div  ref="villa" class="villa-anim"></div>
        
        <video autoplay class="main-vid" src="@/assets/venue/Comp2.webm" muted  playsinline  id="myVideo">
         
        </video>
      </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import Lottie from 'lottie-web';
import json from '../assets/dataq2.json';
import _ from 'lodash';

export default {
  name: 'Login',
  components: {
  },
  data(){
    return {
      showTitle:false,
      json,
      lottie:null,
      mousemoveListener:null,
      images:[
        require('../assets/watercolor/webp/1.webp'),
        require('../assets/watercolor/webp/2.webp'),
        require('../assets/watercolor/webp/3.webp'),
        require('../assets/watercolor/webp/4.webp'),
        require('../assets/watercolor/webp/5.webp'),
        require('../assets/watercolor/webp/6.webp'),
        require('../assets/watercolor/webp/7.webp'),
        require('../assets/watercolor/webp/8.webp'),
        require('../assets/watercolor/webp/9.webp'),
        require('../assets/watercolor/webp/10.webp')
      ]
    }
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.mousemoveListener);
  },
  mounted(){
    
    setTimeout(()=>{
      this.showTitle = true;
    },2000);
    const video = document.querySelector('video');
    if ('play' in video) {
        video.play();
    }
    this.lottie = Lottie.loadAnimation({
      container: this.$refs.villa, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData:json
     // path: '../assets/dataq.json' // the path to the animation json
    });
    this.lottie.play();
    let count = 0;
    let [lastX, lastY] = [0,0];
    const generateRandomNumber =(min,max)=> {
        let highlightedNumber = Math.random() * (max - min) + min;
        return highlightedNumber;
    };
    const drawImage = _.throttle((x,y) => {
      if(this.$refs.droplets == null) return;
      const img = new Image()
      lastX = x;
      lastY = y;
      
      img.src = this.images[count]
      if (count > 8){
        count = 0;
      }else{
        count++;
      }
      let colors = [
        `invert(56%) sepia(45%) saturate(350%) hue-rotate(-40deg) brightness(85%) contrast(85%)`, 
         `invert(25%) sepia(48%) saturate(383%) hue-rotate(-62deg) brightness(98%) contrast(89%)`,
        // `invert(50%) sepia(27%) saturate(395%) hue-rotate(138deg) brightness(94%) contrast(88%)`,
        // `invert(40%) sepia(62%) saturate(333%) hue-rotate(347deg) brightness(94%) contrast(88%)`,
        // `invert(65%) sepia(27%) saturate(381%) hue-rotate(12deg) brightness(86%) contrast(84%)`,
        // `brightness(1) contrast(1)`
      ]
      let randSize = 400 * generateRandomNumber(.5, 1);
      let randOpacity = 1 * generateRandomNumber(.6, 1);
      img.classList.add('dropimg')
      img.style.opacity= randOpacity;
      img.style.width = randSize + 'px';
      img.style.height = randSize + 'px';
      img.style.left = x + 'px';
      img.style.top = y + 'px';
      img.style.filter = colors[generateRandomNumber(0, 1).toFixed(0)]
      this.$refs.droplets.appendChild(img);
      setTimeout(()=>{
        img.classList.add('dismiss');
      },500)
      setTimeout(()=>{
        if(this.$refs.droplets != null){
          this.$refs.droplets.removeChild(img);
        }
        
      },2500)
      
    },100,false)
     window.addEventListener('mousemove', this.mousemoveListener = (event, i)=>{
      if(event.pageY < 100) return;
      if(event.pageX > lastX + 200 || event.pageX < lastX - 200 ||  event.pageY > lastY + 200 || event.pageY < lastY - 200){
        lastX = event.pageX;
        lastY  = event.pageY;
        drawImage(event.pageX, event.pageY);
      }
      
    })
  },
  methods:{
    enter(){
      this.$router.push('/location');
    }
  }
}
</script>
<style scoped lang="scss">

.date{
  font-size:15pt;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, calc(-50% + 90px));
  @include media-query($small){
    font-size: 9pt;
    transform: translate(-50%, calc(-50% - -43px));
  }
}
.description{
  i{
    font-size:25pt;
    color:$light-grey-8;
    transition: all .3s ease;
    cursor: pointer;
    &:hover{
      color:darken($light-grey-8, 50%);
      transform:translateY(10px);
    }
  }
}
.login{
  max-height:calc(100vh - 70px);
  height:100vh;
  position: relative;
  text-align: center;
  display:flex;
  align-items:center;
  overflow: hidden;
  justify-content: center;
  padding-top:70px;
  box-sizing: content-box;

}
.droplets{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  overflow:hidden;
  
}

.animation{

 
  display:flex;
  align-items: center;
  justify-content: center;
  left:0;
  user-select: none;
  width:calc(100% - 40%);
  margin:0% 20%;
  @include media-query($small){
    margin:0;
    width:100%;
    
  }
}
.btn{
  position: fixed;
  overflow:visible;
  display:flex;
  align-items: center;
  justify-content: center;
  height:45px;
  width:150px;
  border:solid 1px #000;
  color:#000;
  bottom:5%;
  left:50%;
  z-index: 100;
  @include media-query($small){
    bottom:4%;
    top:unset;
    transform:translate(-50%, 0);
  }
  transform:translate(-50%, 0);
  transition:all .6s ease;
  cursor: pointer;
  
  &:hover{
    box-shadow: 0px 22px 22px -12px rgba(0,0,0,0.4);
    background:#000;
    color:#fff;
  }
  }
.b-title{
  font-size:50pt;
  color:#645657;
  position: absolute;
  overflow:visible;
  z-index: 1;
  padding-left:10px;
  left:50%;
  top:5%;
  transform:translate(-50%, 0);

  transition:all 1s ease;
  white-space:nowrap;
  @include media-query($small){
   font-size:20pt;
   top:11%;
  }
}
.b-title:hover{
  color:#000;
}
.btn:hover + .main-vid{
  cursor: default;
  @include media-query($small){
    display:none;
  }
  opacity: 1  !important;
  filter: saturate(1) brightness(1) sepia(0) !important ;
}
.btn:hover + .villa-anim{
  cursor: pointer;
  opacity: 1  !important;
  filter: saturate(1) brightness(1) sepia(0) !important ;
}
.main-vid{
 transition:all 1s ease;
  opacity: 1;
  transform:scale(.6,.6) translateY(20px);
  width:100%;
  filter:invert(0%) saturate(1) brightness(1) hue-rotate(0deg) sepia(0.0);
  @include media-query($small){
    display:none;
    height:70%;
   transform:scale(1.8,1.8) translateY(-20px);
  // filter: saturate(0) brightness(.8) sepia(.3) !important ;
  filter: invert(0%) saturate(1) brightness(0.8) hue-rotate(0deg) sepia(0.35);
   opacity: 1;
  }
}
.villa-anim{
  pointer-events: none;
  transition:all 1s ease;
  opacity: .3 ;
  display: none;
  transform:scale(.8,.8) translateY(20px);
  filter:saturate(0) brightness(.8) sepia(.3);
  @include media-query($small){
    display:block;
    transform: unset;
    width: 197vw;
    max-height: 900px;
    max-width: 900px;
   filter: saturate(0) brightness(.8) sepia(.3) !important ;
   opacity: .7;
  }
}

</style>
