import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login';
import Location from '../views/Location';
import Event from '../views/Event';
import Travel from '../views/Travel';
import Dresscode from '../views/Dresscode';
import Accomodations from '../views/Accomodations';
import RSVP from '../views/RSVP';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Dex & Roxana',
      enterClass:'fadeIn',
      leaveClass:'slideOutRight',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/location',
    name: 'Location',
    component: Location,
    
    meta: {
      title: 'Dex & Roxana',
      enterClass:'slideInRight',
      leaveClass:'slideOutLeft',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/event',
    name: 'Event',
    component: Event,
    
    meta: {
      title: 'Dex & Roxana',
      enterClass:'slideInRight',
      leaveClass:'slideOutLeft',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/travel',
    name: 'Travel',
    component: Travel,
    
    meta: {
      title: 'Dex & Roxana',
      enterClass:'slideInRight',
      leaveClass:'slideOutLeft',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/dress-code',
    name: 'Dress Code',
    component: Dresscode,
    
    meta: {
      title: 'Dex & Roxana',
      enterClass:'slideInRight',
      leaveClass:'slideOutLeft',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/accomodations',
    name: 'Accomodations',
    component: Accomodations,
    
    meta: {
      title: 'Dex & Roxana',
      enterClass:'slideInRight',
      leaveClass:'slideOutLeft',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/rsvp',
    name: 'RSVP',
    component: RSVP,
    
    meta: {
      title: 'Dex & Roxana',
      enterClass:'slideInRight',
      leaveClass:'slideOutLeft',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
const DEFAULT_TITLE = 'Some Default Title';
router.afterEach((to, from) => {

      console.log('eyo2')
        document.title = to.meta.title || DEFAULT_TITLE;

});

export default router
