<template>
  <div ref="mapContainer" class="map-container"></div>
</template>
<script>
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGV4aG9uc2EiLCJhIjoiY2luejFndTViMThsNnUya2o5bThpemRsaSJ9.GFlYLJmm5XmM-cGc57UH9g";

export default {
  name: "map",
  data() {
    return {
      map: null
    };
  },
  mounted() {
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/dexhonsa/clqond22h003i01pmfxpn0hff", // Replace with your preferred map style
      center: [11.261757, 43.792711],
      zoom: 11,
      cooperativeGestures: true,
      pitch: 45
    });
    function rotateCamera(timestamp) {
      // clamp the rotation between 0 -360 degrees
      // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
      map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      // Request the next frame of the animation
      requestAnimationFrame(rotateCamera);
    }

    map.on('load', () => {
      // Start the animation.
     // rotateCamera(0);

      // Add 3D buildings and remove label layers to enhance the map
      const layers = map.getStyle().layers;
      for (const layer of layers) {
        if (layer.type === 'symbol' && layer.layout['text-field']) {
          // remove text labels
          // map.removeLayer(layer.id);
        }
      }

      map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      });
    });
    //     map.on("wheel", event => {
    //     if (event.originalEvent.ctrlKey) {
    //         return;
    //     }

    //     if (event.originalEvent.metaKey) {
    //         return;
    //     }

    //     if (event.originalEvent.altKey) {
    //         return;
    //     }

    //     event.preventDefault();
    // });
    var start = [43.780650, 11.328520];
    var end = [43.809305, 11.199127];
    new mapboxgl.Marker({ color: '#6d4139' }).setLngLat([11.328520, 43.780650]).addTo(map);
    new mapboxgl.Marker({ color: '#6d4139' }).setLngLat([11.199127, 43.809305]).addTo(map);
    let getRoute = async () => {
      // make a directions request using cycling profile
      // an arbitrary start will always be the same
      // only the end or destination will change
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${start[1]},${start[0]};${end[1]},${end[0]}?steps=true&geometries=geojson&access_token=pk.eyJ1IjoiZGV4aG9uc2EiLCJhIjoiY2luejFndTViMThsNnUya2o5bThpemRsaSJ9.GFlYLJmm5XmM-cGc57UH9g
`,
        { method: 'GET' }
      );
      const json = await query.json();
      const data = json.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route
        }
      };
      // if the route already exists on the map, we'll reset it using setData
      if (map.getSource('route')) {
        map.getSource('route').setData(geojson);
      }
      // otherwise, we'll make a new request
      else {
        map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75
          }
        });
      }
      // add turn instructions here at the end
    }

    map.on('load', () => {
      // make an initial directions request that
      // starts and ends at the same location
      getRoute(start);

      // Add starting point to the map
      map.addLayer({
        id: 'point',
        type: 'circle',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: start
                }
              }
            ]
          }
        },
        paint: {
          'circle-radius': 10,
          'circle-color': '#3887be'
        }
      });
      // this is where the code from the next step will go
    });

    getRoute();

    this.map = map;
  },
  unmounted() {
    this.map.remove();
    this.map = null;
  }
};
</script>
<style lang="scss" scoped>
.map-container {
  flex: 1;
  color: #6d4139;
  height: 60vh;
  width: 100%;
  margin-bottom: 55px;

  @include media-query($small) {
    height: 50vh;
  }
}
</style>
