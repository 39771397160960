<template>
<div  class="page-s">
    <div  class="content">
        
        <!-- <div class="bg fadeIn animated" style="animation-delay:1s"></div> -->
        <div style="position:absolute;top:70px;left:0;width:100%; z-index: 1; height:calc(100vh - 70px)"></div>
        <div style="pointer-events: none !important; z-index: 0;" class="home ">
            <h1  class="fadeIn animated sackers h-title2" style="animation-delay:1s">CIAO</h1>
            

            



<h1  ref="ciao" class="fadeIn animated sackers h-title text-b" style="animation-delay:.5s">CIAO</h1>
            <video class="animated-slow fadeIn" src="@/assets/villa-video.mp4"  autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;" :controls="false"  preload="auto"></video>
            
        </div>
        
        <section>
            <div class="page-width">
                <div class="header-t amalfi"><span class="ss02">t</span>he Villa Gamberai<span class="ss01">a</span></div>
                <div class="header-s sackers">Our Wedding will take place at the Villa Gamberaia in Florence Italy.  It is a beautiful villa with magnificent gardens and landscapes.  A perfect location for a perfect event!</div>
            </div>
        </section>
        
        
        <!-- <section>
            <div class="page-width">
                <div class="d-container center align-center">
                    <div v-motion-slide-visible-right="!isMobile" class="box"></div>  
                </div>              
            </div>
        </section> -->
        <section style="margin-top:25px;">
            <Flicking ref="flicking" :plugins="plugins" :options="{ circular: !isMobile }">
                <!-- <div class="image3" ><img src="@/assets/venue_compressed/1.webp" alt=""></div> -->
                <div class="image3" ><img src="@/assets/venue_compressed/2.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/3.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/4.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/5.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/6.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/7.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/8.webp" alt=""></div>
                <div class="image3" ><img src="@/assets/venue_compressed/9.webp" alt=""></div>
                <template #viewport>
                    <div class="flicking-pagination"></div>
                </template>
            </Flicking>
        </section>
        <section>
                <div style="height:300px;"></div>
            </section>

        
        <!-- <div  class="box"></div>
        <div v-motion-slide-visible-right class="box"></div>
        <div v-motion-slide-visible-left class="box"></div> -->

    </div>
</div>
</template>
<script>
import Map from '../components/Map.vue';


import Flicking from "@egjs/vue3-flicking";
    import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
    import "@egjs/flicking-plugins/dist/pagination.css";
    const plugins = [new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: false }), new Pagination({type:'bullet'})];

export default {
    components:{
        Map,
        Flicking
    },
    name:'Location',
    data(){
        return {
            isLoading:false,
            aimX:null,
            aimY:null,
            mousemoveListener:null,
            resizeListener:null,
            plugins
        }
    },
    computed:{
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.mousemoveListener)
        window.removeEventListener('resize', this.resizeListener)
    },
    methods:{
        gogo(){
            let h = window.innerHeight;
            window.scrollTo(0, h);
        }
    },
    mounted(){
        
        // function requestDeviceOrientation () {
        // if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
        // DeviceOrientationEvent.requestPermission()
        // .then(permissionState => {
        // if (permissionState === 'granted') {
        // window.addEventListener('deviceorientation', () => {});
        // }
        // })
        // .catch(console.error);
        // } else {
        // // handle regular non iOS 13+ devices
        // console.log ("not iOS");
        // }
        // }
        // requestDeviceOrientation();
       
        // let currentX = 0;
        //     let currentY = 0;
        //     this.aimX = 0;
        //     this.aimY = 0;
        //     let speed = 0.1;
        //     let windowHeight = 0;
        //     if(this.$refs.ciao == null) return;
        //     let {width, height} = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect();
            
        //     window.addEventListener('resize', ()=>{
        //         height = this.$refs.ciao && this.$refs.ciao.getBoundingClientRect().height;
        //         windowHeight = window.innerHeight;
        //     });
        //     const animate = () => {
        //         currentX += (this.aimX - currentX) * speed;
        //         currentY += (this.aimY - currentY) * speed;
        //         if(this.$refs.ciao == null)return;
        //         this.$refs.ciao.style.backgroundPosition = `${currentX}px ${currentY - windowHeight/2}px`
        //         requestAnimationFrame(animate)
        //     }
        //     animate();
        //     window.addEventListener('mousemove', (e)=>{
                
        //         this.aimX = e.pageX/34;
        //         this.aimY = e.pageY/34
               
        //     })
        
        
        // if(window.DeviceOrientationEvent) {
        //     window.addEventListener('deviceorientation', (event) => {
                
        //     //let alpha = event.alpha;
        //      this.aimX = event.gamma /2;
        //      this.aimY = 150 + event.beta/2;
        // // let beta = event.beta;
        // // let gamma = event.gamma;
        //     // Do something
        //     });
        // }
    },

}
</script>
<style scoped lang="scss">
    .image3{
        height:50vh;
        width:25%;
        position:relative;
        padding:1%;
        
        @include media-query($small){
            height:50vh;
            width:100%;
        }
        img{
            box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.3);
            object-fit: cover;
            height:100%;
            width:100%;
        }
    }
.section1{
        display:flex;
        align-items: center;
        gap:5%;
        @include media-query($small){
            flex-wrap: wrap;
        }
        &.reverse{
            flex-direction: row-reverse;
            @include media-query($small){
                flex-direction: row;
            }
        }
        .sec-img{
            @include media-query($small){
                flex-basis: 100%;
            }
            flex:1;
            // max-width:400px;

            
            position: relative;
            img{
                @include media-query($small){
                    max-height:300px;
                }
                box-shadow: 0px 6px 20px -5px rgba(0,0,0,0.3);
                height:100%;
                width:100%;
                object-fit: cover;
            }
        }
        .sec-body{
            flex:1;
             
             @include media-query($small){
                margin-bottom:55px;
            }
        h2{
            font-size: 34pt;
            color:#5e5253;
            @include media-query($small){
                font-size: 24pt;
                text-align: center;
            }
            
        }
        span{

        }
        .color-p{
            margin:20px 0px;
            display:flex;
            align-items: center;
            gap:20px;
            .color{
                border:solid 2px #5e5253;
                height:65px;
                width:65px;
                border-radius: 100px;
                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.2);
                &.pink1{
                    background:#733C50;
                }
                &.pink2{
                    background:#9D6371;
                }   
                &.pink3{
                    background:#DDB5BE;
                }
                &.pink4{
                    background:#CCB2A3;
                }
                &.pink5{
                    background:#F2EEEB;
                }
            }
        }
    }
    }
.header-t{
    font-size:45pt;
    margin:10px 0px;
    margin-top:55px;
    text-align:center;
    color:#5e5253;
    // text-shadow: 1px 1px 0px #fff, -1px -1px 1px #5e5253;
    @include media-query($small){
        font-size:25pt;
        margin-bottom:25px;
    }
}
.header-s{
    font-size:12pt;
    color:#645657;
    text-align:center;
    @include media-query($small){
        font-size:10pt;
    }
}
.bg{
    &::before{
        content:'';
        height: 100%;
        position:absolute;
        z-index: 3;
        top:0;
        left:0;
        width:100%;
        background:linear-gradient(180deg, #00000080, transparent);
    }
    display:none;
    background-image: url('../assets/venue/wide2.webp');
    background-size:cover;
    background-position: center;
    position: absolute;
    
    left:0;
    height:calc(100vh - 70px);
    width:100%;
    pointer-events: none;
    z-index: 1;
}
.h-title{
        position: relative;
       // -webkit-background-clip: text;
       // -webkit-text-fill-color: transparent;
        // background-image: url('../assets/venue/wide2.webp');
        background-size: cover;
        background-position: center;
        z-index: 10;
        color:#fff;
        font-size:30vw;
        padding-top:50px;
        
        @include media-query($small){
            padding-top:0;
        }
        
        user-select: none;
    
    }
    .h-title2{
        position: absolute;
        
        background-size: cover;
        background-position: center;
        z-index: 7;
        text-shadow: 3px 13px 10px rgba(0,0,0,0.3);
        color:transparent;
        font-size:30vw;
        padding-top:50px;
        @include media-query($small){
            padding-top:0;
        }
        user-select: none;
    
    }
.content{
    position: relative;
    min-height:100%;
    width:100%;
    overflow: hidden;
}
.box{
    height:1000px;

        @include media-query($small){
            height:300px;
        }
    background-image: url('../assets/venue/wide2.webp');
    background-size: cover;
    background-position: center;
    margin:25px;
    width:100%;
    max-width: 1000px;
    border:solid 1px #000;
}
.box2{
    background-image: url('../assets/venue/1.jpg');
}
.home{
    max-height:calc(100vh - 70px);
    padding-top:000px;
    width:100%;
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    svg{
        height:0;
        width:0;
    }
    video{

        position:absolute;
        min-width:100%;
        left:0;
        min-height:100%;
        z-index: 1;
        top:0;
        @include media-query($small){
            left:-100%;
        }
    }

}
</style>