import { createApp, mixin } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store';
import  { MotionPlugin } from '@vueuse/motion'

import './assets/css/style.css';
import './assets/css/all.css';
import './assets/css/animate.css';
import './assets/css/main.css';
import "@egjs/vue3-flicking/dist/flicking-inline.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import "@egjs/flicking-plugins/dist/pagination.css";


import Button from "./components/Button.vue";
import Input from './components/Input.vue';
import Spinner from './components/Spinner.vue';
import Checkbox from './components/Checkbox.vue';


import Vuex from 'vuex';
const GetGuid = () => {
	return Math.floor(Math.random() * 999999);
}


let app = createApp(App)
app.component("v-btn", Button);
app.component("v-input", Input);
app.component("v-spinner", Spinner);
app.component("v-checkbox", Checkbox);
app.config.globalProperties.$uuid4 = () => GetGuid().toString();
app.use(store).use(Vuex).use(router).use(MotionPlugin).mount('#app')